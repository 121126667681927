import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import ContentEditable from 'react-contenteditable';

import EditorActions from './EditorActions';
import Checklist from './Checklist';
import Password from './EditorPassword';
import Rating from './EditorRating';

import global from '../Global';
import { randomId } from '../../js/functions';

class Editor extends Component {
    constructor(props) {
        super(props);
        this._editorContent = React.createRef();
        this.parentstate = props.parent.localstate;
    }

    localstate = observable({
        starting: true,
    });

    async editorAction(type) {
        const textSelection = window.getSelection();
        if (!textSelection.anchorNode) return;
        const range = textSelection.getRangeAt(0);
        const execCommands = ['bold', 'italic', 'underline', 'strikethrough', 'undo', 'redo'];

        if (execCommands.includes(type)) {
            document.execCommand(type, false, null);
        }

        if (type.startsWith('heading')) {
            const size = type.match(/\d+/)[0];
            const heading = document.createElement(`h${size}`);
            heading.innerText = textSelection.toString();
            range.deleteContents();
            range.insertNode(heading);
            this.setCursorPos(heading);
        }

        if (type.startsWith('empty')) {
            const div = document.createElement('div');
            const br = document.createElement('br');
            const container = this.findContentEditableContainer(range.commonAncestorContainer);
            div.appendChild(br);
            if (container) {
                if (type === 'emptyBefore') container.before(div);
                if (type === 'emptyAfter') container.after(div);
                div?.focus();
            }
        }

        if (type.startsWith('align')) {
            const align = type.split('-')[1];
            const span = document.createElement('span');
            span.className = `align-${align}`;
            span.innerText = textSelection.toString();
            range.deleteContents();
            range.insertNode(span);
            this.setCursorPos(span);
        }

        if (type === 'link') {
            const url = window.prompt('URL des Links');
            if (!url) return;
            const domainMatch = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/);
            const a = document.createElement('a');
            a.href = url;
            a.textContent = textSelection.toString() || (domainMatch ? domainMatch[1] : url);
            a.target = '_blank';
            a.contentEditable = false;
            range.deleteContents();
            range.insertNode(a);
        }

        if (type === 'code') {
            await document.execCommand('formatBlock', false, 'pre');
            document.querySelectorAll('#editor-content pre').forEach(element => {
                this.setAttributeForAll(element, 'spellcheck', false);
            });
        }

        if (type === 'insertList') {
            const ul = document.createElement('ul');
            const li = document.createElement('li');
            li.innerText = textSelection.toString();
            ul.appendChild(li);
            range.deleteContents();
            range.insertNode(ul);
            this.setCursorPos(li);
        }

        if (type === 'insertChecklist') {
            const component = (
                <Checklist
                    content={[{ id: randomId(5), checked: false, text: textSelection.toString() }]}
                    parentstate={this.localstate}
                />
            );
            this.inserComponent(component, 'checklist-container');
        }

        if (type === 'insertPassword') {
            const component = (
                <Password
                    content={{ id: randomId(5), description: textSelection.toString(), username: '', password: '' }}
                    parentstate={this.localstate}
                />
            );
            this.inserComponent(component, 'password-container');
        }

        if (type === 'insertRating') {
            const component = (
                <Rating
                    content={{
                        settings: {
                            sorting: { prop: 'date', dir: 'asc' },
                            ratingLimit: 10,
                        },
                        items: [
                            {
                                id: randomId(5),
                                name: textSelection.toString(),
                                rating: 0,
                                comment: '',
                                date: moment().format(),
                            },
                        ],
                    }}
                    parentstate={this.localstate}
                />
            );
            this.inserComponent(component, 'rating-container');
        }
    }

    inserComponent(component, className = '') {
        const textSelection = window.getSelection();
        if (!textSelection.anchorNode) return;
        const range = textSelection.getRangeAt(0);
        const container = this.findContentEditableContainer(range.commonAncestorContainer);
        const div = document.createElement('div');
        if (className) div.className = className;

        range.deleteContents();

        if (container) {
            if (container?.id === 'editor-content') {
                container.appendChild(div);
            } else {
                container.after(div);
            }
        } else {
            range.insertNode(div);
        }

        ReactDOM.createRoot(div).render(component);
    }

    findContentEditableContainer(element) {
        if (element?.parentNode?.id === 'editor-content') return element;

        while (element.parentNode) {
            const child = element;
            element = element.parentNode;
            if (element?.id === 'editor-content') return child;
        }

        return null;
    }

    initComponents() {
        const editor = this._editorContent.current;
        const checklists = editor.querySelectorAll('.checklist-container');
        const passwords = editor.querySelectorAll('.password-container');
        const ratings = editor.querySelectorAll('.rating-container');

        for (const container of checklists) {
            const node = container.querySelector('.checklist');
            const content = JSON.parse(node?.dataset?.state || '[]');
            const component = <Checklist content={content} parentstate={this.localstate} />;
            ReactDOM.createRoot(container).render(component);
        }

        for (const container of passwords) {
            const node = container.querySelector('.password');
            const content = JSON.parse(node?.dataset?.state || '{}');
            const component = <Password content={content} parentstate={this.localstate} />;
            ReactDOM.createRoot(container).render(component);
        }

        for (const container of ratings) {
            const node = container.querySelector('.rating');
            const content = JSON.parse(node?.dataset?.state || '{}');
            const component = <Rating content={content} parentstate={this.localstate} />;
            ReactDOM.createRoot(container).render(component);
        }
    }

    setCursorPos(element) {
        const textSelection = window.getSelection();
        if (!textSelection.anchorNode) return;
        const range = document.createRange();
        range.setStartAfter(element?.lastChild || element);
        range.collapse(true);
        textSelection.removeAllRanges();
        textSelection.addRange(range);
    }

    setAttributeForAll(element, attribute, value) {
        if (element.nodeType === Node.ELEMENT_NODE) {
            element[attribute] = value;
            for (let i = 0; i < element.children.length; i++) {
                this.setAttributeForAll(element.children[i], attribute, value);
            }
        }
    }

    componentDidMount() {
        this.initComponents();

        document.querySelectorAll('#editor-content pre').forEach(element => {
            this.setAttributeForAll(element, 'spellcheck', false);
        });

        this.parentstate.initialNoteContent = this._editorContent.current?.innerHTML || '';

        setTimeout(() => {
            this.localstate.starting = false;
            // if (global.screen.width > 1024) this._editorContent.current?.focus();
        }, 100);
    }

    render() {
        const { noteContent } = this.parentstate;

        return (
            <div id="editor">
                <ContentEditable
                    id="editor-content"
                    innerRef={this._editorContent}
                    html={noteContent}
                    onChange={() => {
                        global.preview.noteModified++;
                        // this.parentstate.noteContent = e.target?.value || "";
                    }}
                />
            </div>
        );
    }
}

//############################################################################# Export

Editor = observer(Editor);

export { Editor, EditorActions };
