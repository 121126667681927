import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Select from 'react-select';
import Popup from 'reactjs-popup';

import global from '../Global';
import { msg } from '../../js/functions';

const voicesRenamed = [
    { name: 'Microsoft Hedda - German (Germany)', label: 'Deutsch (Microsoft, w1)' },
    { name: 'Microsoft Katja - German (Germany)', label: 'Deutsch (Microsoft, w2)' },
    { name: 'Microsoft Stefan - German (Germany)', label: 'Deutsch (Microsoft, m)' },
    { name: 'Google Deutsch', label: 'Deutsch (w)' },
    { name: 'Google US English', label: 'Englisch (US, w)' },
    { name: 'Google UK English Female', label: 'Englisch (UK, w)' },
    { name: 'Google UK English Male', label: 'Englisch (UK, m)' },
    { name: 'Google español', label: 'Spanisch (m)' },
    { name: 'Google español de Estados Unidos', label: 'Spanisch (w)' },
    { name: 'Google français', label: 'Französisch (w)' },
    { name: 'Google italiano', label: 'Italienisch (w)' },
    { name: 'Google Nederlands', label: 'Niederländisch (m)' },
    { name: 'Google polski', label: 'Polnisch (w)' },
    { name: 'Google русский', label: 'Russisch (w)' },
    { name: 'Google português do Brasil', label: 'Portugisisch (w)' },
    { name: 'Google Bahasa Indonesia', label: 'Indonesisch (w)' },
    { name: 'Google 國語（臺灣）', label: 'Chinesisch (w)' },
    { name: 'Google 日本語', label: 'Japanisch (w)' },
    { name: 'Google 한국의', label: 'Koreanisch (w)' },
];

let interval = null;

class SpeechPopup extends Component {
    localstate = observable({
        speech: null,
        loading: true,
        isPlaying: false,
        options: [],
        chunks: [],
        lastSelection: null,
        textToSpeak: '',
        settings: {
            voice: 3,
            pitch: 1,
            speed: 1,
        },
    });

    play() {
        const { speech, settings, textToSpeak, chunks } = this.localstate;

        if (chunks.length === 0) {
            this.restoreSelection();
            this.stop();
        }

        if (speech) {
            if (chunks.length === 0) {
                const chunks = this.splitIntoChunks(textToSpeak.replace(/\n/g, ', '), 250);

                if (!textToSpeak || chunks.length === 0) {
                    msg('Es gibt keinen Text zum Vorlesen', null, 'fal fa-exclamation-circle');
                    return;
                }

                this.localstate.chunks = chunks;
            }

            this.localstate.loading = true;

            speech.voice = settings.voice?.value;
            speech.rate = settings.speed;
            speech.pitch = settings.pitch;
            speech.text = this.localstate.chunks[0];

            window.speechSynthesis.speak(speech);
            this.localstate.chunks.splice(0, 1);
        } else {
            msg('Dein Browser unterstüzt das Vorlesen von Texten leider nicht', null, 'fal fa-exclamation-circle');
        }
    }

    stop() {
        clearInterval(interval);
        window.speechSynthesis.cancel();
        this.localstate.isPlaying = false;
        this.localstate.loading = false;
        this.localstate.chunks = [];
    }

    onSpeechButton() {
        const textSelection = window.getSelection();
        const textSelected = textSelection.anchorNode && textSelection?.toString();

        this.localstate.lastSelection = textSelected
            ? [textSelection.anchorNode, textSelection.anchorOffset, textSelection.focusNode, textSelection.focusOffset]
            : null;
        this.localstate.textToSpeak = textSelected
            ? textSelection.toString()
            : document.getElementById('note-content').textContent;
    }

    restoreSelection() {
        const { screen } = global;
        const { lastSelection } = this.localstate;
        if (screen.width <= 1024) return;

        if (lastSelection) {
            const textSelection = window.getSelection();
            textSelection.setBaseAndExtent(lastSelection[0], lastSelection[1], lastSelection[2], lastSelection[3]);
        }
    }

    splitIntoChunks(str = '', size = 100) {
        const chunks = [];
        const words = str.split(/\s/).filter(x => x?.length > 0);

        for (let i = 0; i < words.length; i += size) {
            chunks.push(words.slice(i, i + size).join(' '));
        }
        return chunks;
    }

    componentDidMount() {
        if ('speechSynthesis' in window) {
            const speechSynthesis = window.speechSynthesis;
            const speech = new SpeechSynthesisUtterance();
            let loadingInterval = null;

            loadingInterval = setInterval(() => {
                const voices = speechSynthesis.getVoices();

                if (voices.length > 0) {
                    const { settings } = this.localstate;
                    const originalVoices = [];
                    clearInterval(loadingInterval);

                    speech.voice = voices[3];
                    speech.rate = settings.speed;
                    speech.pitch = settings.pitch;

                    this.localstate.options = voicesRenamed
                        .map(item => {
                            const voice = voices.find(x => x.name === item.name);
                            if (!voice) originalVoices.push({ label: item.label, value: voice });
                            return voice ? { label: item.label, value: voice } : null;
                        })
                        .filter(x => x !== null);

                    for (const voice of voices) {
                        const foundRenamed = voicesRenamed.find(x => x.name === voice.name);
                        if (!foundRenamed) originalVoices.push({ label: voice.name, value: voice });
                    }

                    this.localstate.options = this.localstate.options.concat(originalVoices);

                    const defaultVoice = this.localstate.options[3] || this.localstate.options[0] || null;
                    this.localstate.settings.voice = defaultVoice
                        ? { label: defaultVoice.label, value: defaultVoice.value }
                        : null;
                    this.localstate.speech = speech;
                    this.localstate.loading = false;
                }
            }, 100);

            this.setSpeechEvents(speech);
        } else {
            this.localstate.loading = false;
        }
    }

    setSpeechEvents(speech) {
        speech.addEventListener('start', () => {
            const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
            this.localstate.isPlaying = true;
            this.localstate.loading = false;

            if (!isFirefox) {
                interval = setInterval(() => {
                    window.speechSynthesis.pause();
                    window.speechSynthesis.resume();
                }, 5000);
            }
        });

        speech.addEventListener('end', () => {
            const { chunks } = this.localstate;

            this.localstate.isPlaying = false;
            this.localstate.loading = false;

            if (chunks.length > 0) this.play();
        });

        speech.addEventListener('error', () => {
            this.localstate.isPlaying = false;
            this.localstate.loading = false;
        });
    }

    componentWillUnmount() {
        clearInterval(interval);
        this.stop();
    }

    render() {
        const { screen, selectSmallStyle } = global;
        const { options, settings, loading, isPlaying } = this.localstate;

        return (
            <Popup
                trigger={
                    <button className="icon" onMouseUp={() => this.onSpeechButton()}>
                        <i className="fal fa-volume"></i>
                    </button>
                }
                position={screen.width <= 650 ? 'bottom right' : 'bottom center'}
                on="click"
                onOpen={() => this.restoreSelection()}
                {...{
                    contentStyle: {
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '0.5em',
                        padding: '1em 0.5em',
                    },
                }}
            >
                <Select
                    options={options}
                    value={settings.voice}
                    maxMenuHeight="50vh"
                    placeholder="Sprache"
                    isDisabled={loading || isPlaying}
                    styles={{
                        ...selectSmallStyle,
                        container: baseStyles => ({ ...baseStyles, width: '12em' }),
                    }}
                    onChange={select => (this.localstate.settings.voice = select)}
                />
                <label className="horizontal">
                    <p style={{ width: '4em' }}>Speed</p>
                    <input
                        type="range"
                        min={0.1}
                        max={1.5}
                        step={0.1}
                        value={settings.speed}
                        disabled={loading || isPlaying}
                        onChange={e => {
                            if (isPlaying) this.stop();
                            this.localstate.settings.speed = Number(e.target.value);
                        }}
                    />
                    <small style={{ width: '2.8em' }}>{parseInt(settings.speed * 100)}%</small>
                </label>
                <label className="horizontal">
                    <p style={{ width: '4em' }}>Tonhöhe</p>
                    <input
                        type="range"
                        min={0.2}
                        max={1.6}
                        step={0.2}
                        value={settings.pitch}
                        disabled={loading || isPlaying}
                        onChange={e => {
                            if (isPlaying) this.stop();
                            this.localstate.settings.pitch = Number(e.target.value);
                        }}
                    />
                    <small style={{ width: '2.8em' }}>{parseInt(settings.pitch * 100)}%</small>
                </label>
                <div className="buttons">
                    {isPlaying ? (
                        <button className="primary small" disabled={loading} onClick={() => this.stop()}>
                            <i className="fal fa-stop"></i>
                            <span>Stop</span>
                        </button>
                    ) : (
                        <button
                            className={`primary small${loading ? ' loading' : ''}`}
                            disabled={loading}
                            onClick={() => this.play()}
                        >
                            <i className="fal fa-play"></i>
                            <span>Vorlesen</span>
                        </button>
                    )}
                </div>
            </Popup>
        );
    }
}

export default observer(SpeechPopup);
