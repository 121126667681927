import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

import global from './Global';
import { getPath, msg, cleanSharePath } from '../js/functions';
import { update } from '../js/helper';

//############################################################################# Effect

const effect = {
    upload: {
        init: {
            y: 30,
            opacity: 0,
        },
        in: {
            y: 0,
            opacity: 1,
            transition: {
                y: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
            },
        },
        out: {
            y: -30,
            opacity: 0,
            transition: {
                y: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
            },
        },
    },
    uploadProgress: {
        init: {
            height: 0,
            opacity: 0,
        },
        in: {
            height: '0.8em',
            opacity: 1,
            transition: {
                height: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
            },
        },
        out: {
            height: 0,
            opacity: 0,
            transition: {
                height: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
            },
        },
    },
};

//#############################################################################

class Upload extends Component {
    constructor() {
        super();
        this.uploadInput = React.createRef();
    }

    async onUpload(files) {
        const { user, sharedFolder, uploads } = global;
        const formData = new FormData();

        if (files.length === 0) {
            return;
        }

        for (const file of files) {
            const fileUser = `${sharedFolder?.owner || user?.name || ''}/`;
            const filePath = cleanSharePath(getPath());

            formData.append('files', file);

            const fileFromUplodList = uploads.find(x => {
                return x.name === file.name && x.user === fileUser && x.path === filePath;
            });

            if (!fileFromUplodList) {
                global.uploads.push({
                    uploadFile: file,
                    name: file.name,
                    user: fileUser,
                    path: filePath,
                    status: 'waiting',
                    progress: 0,
                });
            } else {
                fileFromUplodList.status = 'waiting';
                fileFromUplodList.progress = 0;

                msg('Dateien mit gleichen Namen werden überschrieben', null, 'fal fa-info-circle');
            }
        }

        this.uploadInput.current.value = '';

        this.uploadFile();
    }

    async uploadFile() {
        const { uploads } = global;
        const formData = new FormData();
        const file = uploads.find(x => x.status === 'waiting' && x.progress === 0);

        if (!file) {
            msg(
                'Upload abgeschlossen',
                `${uploads.length} Datei${uploads.length === 1 ? '' : 'en'} kürzlich hochgeladen`,
                'fal fa-check'
            );
            return;
        }

        file.status = 'preparing';

        formData.append('files', file.uploadFile);
        formData.append('user', file.user);
        formData.append('path', file.path);

        try {
            const response = await axios.post(`${global.url}/upload.php`, formData, {
                onUploadProgress: e => {
                    const { loaded, total } = e;
                    const percentage = Math.round((loaded * 100) / total);

                    if (file.status !== 'uploading') {
                        file.status = 'uploading';
                    }

                    file.progress = percentage;
                },
            });

            if (!response?.data?.success) throw response?.data?.error || response?.data;
            if (response?.data?.code !== 200) throw response?.data?.result;

            file.status = 'completed';

            update('upload', {
                path: file.path || '/',
            });

            this.uploadFile();
        } catch (e) {
            console.error(e);
            msg('Eine Datei konnte nicht hochgeladen werden', file.name, 'fal fa-xmark-circle');

            file.status = 'error';
            this.uploadFile();
        }
    }

    render() {
        const { uploads } = global;
        // const isUploading = uploads.filter(x => x.status === 'uploading').length > 0;

        return (
            <>
                <div id="uploads">
                    <AnimatePresence>
                        {uploads.map((file, i) => (
                            <UploadItem key={i} file={file} />
                        ))}
                    </AnimatePresence>

                    {global.uploads.length > 0 && (
                        <div className="buttons">
                            <button
                                className="default tiny"
                                // disabled={isUploading}
                                onClick={() => {
                                    global.uploads = uploads.filter(x => x.status === 'uploading');
                                }}
                            >
                                <span>Uploads leeren</span>
                            </button>
                        </div>
                    )}
                </div>

                <input
                    id="input-upload"
                    ref={this.uploadInput}
                    type="file"
                    multiple
                    hidden
                    onChange={e => this.onUpload(e.target.files)}
                />
            </>
        );
    }
}

//############################################################################# Upload Item

class UploadItem extends Component {
    render() {
        const { animation } = global;
        const { file, noexit } = this.props;

        return (
            <motion.div
                className="upload-item"
                initial={animation.fade.init}
                animate={animation.fade.in}
                exit={animation.fade.out}
            >
                <div className="status">
                    <AnimatePresence>
                        {file?.status === 'waiting' && (
                            <motion.div
                                initial={effect.upload.init}
                                animate={effect.upload.in}
                                exit={effect.upload.out}
                                title="In der Warteschlange"
                            >
                                <i className="fal fa-clock"></i>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {file?.status === 'preparing' && (
                            <motion.div
                                className="preparing"
                                initial={effect.upload.init}
                                animate={effect.upload.in}
                                exit={effect.upload.out}
                                title="Wird verarbeitet..."
                            >
                                <i className="fal fa-clock-twelve"></i>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {file?.status === 'uploading' && (
                            <motion.div
                                className="uploading"
                                initial={effect.upload.init}
                                animate={effect.upload.in}
                                exit={effect.upload.out}
                                title="Wird hochgeladen..."
                            ></motion.div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {file?.status === 'completed' && (
                            <motion.div
                                initial={effect.upload.init}
                                animate={effect.upload.in}
                                exit={effect.upload.out}
                                title="Upload abgeschlossen"
                            >
                                <i className="fal fa-check"></i>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {file?.status === 'error' && (
                            <motion.div
                                initial={effect.upload.init}
                                animate={effect.upload.in}
                                exit={effect.upload.out}
                                title="Upload fehlgeschlagen"
                            >
                                <i className="fal fa-xmark"></i>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div className="flex-col">
                    <p title={file?.name}>{file?.name}</p>
                    <AnimatePresence>
                        {(file?.status === 'uploading' || noexit) && (
                            <motion.div
                                className="progress"
                                initial={effect.uploadProgress.init}
                                animate={effect.uploadProgress.in}
                                exit={effect.uploadProgress.out}
                            >
                                <div
                                    style={{
                                        width: `${file?.progress || '0'}%`,
                                    }}
                                ></div>
                                <span>{file?.progress || '0'} %</span>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </motion.div>
        );
    }
}

Upload = observer(Upload);
UploadItem = observer(UploadItem);

export { Upload, UploadItem };
