import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Select from 'react-select';
import Popup from 'reactjs-popup';
import translate from 'translate';

import SpeechPopup from './Speech';

import global from '../Global';
import { msg } from '../../js/functions';

const options = [
    { label: 'Deutsch', value: 'de' },
    { label: 'Englisch', value: 'en' },
    { label: 'Spanisch', value: 'es' },
    { label: 'Französisch', value: 'fr' },
    { label: 'Italienisch', value: 'it' },
    { label: 'Niederländisch', value: 'nl' },
    { label: 'Polnisch', value: 'pl' },
    { label: 'Russisch', value: 'ru' },
    { label: 'Portugisisch', value: 'pt' },
    { label: 'Indonesisch', value: 'id' },
    { label: 'Chinesisch', value: 'zh' },
    { label: 'Japanisch', value: 'ja' },
    { label: 'Koreanisch', value: 'ko' },
];

class NoteActions extends Component {
    constructor(props) {
        super(props);
        this.parentstate = props.parent.localstate;
    }

    localstate = observable({
        loading: false,
        langFrom: options[0],
        langTo: options[1],
        textToTranslate: '',
        lastSelection: null,
    });

    onTranslateButton() {
        const textSelection = window.getSelection();
        const textSelected = textSelection.anchorNode && textSelection?.toString();

        if (!textSelected || !window.getSelection()) {
            this.localstate.lastSelection = null;
            return;
        }

        this.localstate.textToTranslate = textSelection.toString();
        this.localstate.lastSelection = [
            textSelection.anchorNode,
            textSelection.anchorOffset,
            textSelection.focusNode,
            textSelection.focusOffset,
        ];
    }

    async translateText() {
        const { noteTextMode } = this.parentstate;
        const { langFrom, langTo, textToTranslate } = this.localstate;

        if (!textToTranslate) {
            msg('Wähle einen Text zum Übersetzen aus', null, 'fal fa-exclamation-circle');
            return;
        }

        this.localstate.loading = true;

        const translated = await translate(textToTranslate, {
            from: langFrom.value,
            to: langTo.value,
            engine: 'google',
        });

        this.restoreSelection();

        if (noteTextMode === 'editor') {
            document.execCommand('insertText', false, translated);
        } else {
            const textarea = document.querySelector('#note-content textarea');

            if (!textarea) {
                msg('Übersetzung konnte nicht eingefügt werden', null, 'fal fa-exclamation-circle');
                return;
            }

            const first = textarea.value.slice(0, textarea.selectionStart);
            const rest = textarea.value.slice(textarea.selectionEnd, textarea.value.length);

            textarea.value = first + translated + rest;
            textarea.selectionEnd = (first + translated).length;
        }
        this.localstate.loading = false;
    }

    exchangeLanguage() {
        const { langFrom, langTo } = this.localstate;
        this.localstate.langFrom = { ...langTo };
        this.localstate.langTo = { ...langFrom };
    }

    restoreSelection() {
        const { lastSelection } = this.localstate;

        if (lastSelection) {
            const textSelection = window.getSelection();
            textSelection.setBaseAndExtent(lastSelection[0], lastSelection[1], lastSelection[2], lastSelection[3]);
        }
    }

    render() {
        const { screen, selectSmallStyle } = global;
        const { loading, langFrom, langTo } = this.localstate;

        return (
            <div className="actions-buttons">
                <Popup
                    trigger={
                        <button
                            className="icon"
                            disabled={loading}
                            title="Übersetzen"
                            onMouseDown={() => this.onTranslateButton()}
                        >
                            <i className="fal fa-language"></i>
                        </button>
                    }
                    position={screen.width <= 650 ? 'bottom right' : 'bottom center'}
                    on="click"
                    onOpen={() => this.restoreSelection()}
                    {...{
                        contentStyle: {
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '0.5em',
                            padding: '1em 0.5em',
                        },
                    }}
                >
                    <Select
                        options={options}
                        value={langFrom}
                        maxMenuHeight="50vh"
                        placeholder="Sprache"
                        isDisabled={loading}
                        styles={{
                            ...selectSmallStyle,
                            container: baseStyles => ({ ...baseStyles, width: '12em' }),
                        }}
                        onChange={select => (this.localstate.langFrom = select)}
                    />
                    <div className="flex-row">
                        <button className="icon" onClick={() => this.exchangeLanguage()}>
                            <i className="fal fa-arrow-down-arrow-up"></i>
                        </button>
                    </div>
                    <Select
                        options={options}
                        value={langTo}
                        maxMenuHeight="50vh"
                        placeholder="Sprache"
                        isDisabled={loading}
                        styles={{
                            ...selectSmallStyle,
                            container: baseStyles => ({ ...baseStyles, width: '12em' }),
                        }}
                        onChange={select => (this.localstate.langTo = select)}
                    />
                    <div className="buttons">
                        <button className="primary small" disabled={loading} onClick={() => this.translateText()}>
                            <i className="fal fa-language"></i>
                            <span>Übersetzen</span>
                        </button>
                    </div>
                </Popup>
                <SpeechPopup />
            </div>
        );
    }
}

export default observer(NoteActions);
