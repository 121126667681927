import React, { Component } from 'react';
import { observable } from 'mobx';
import Select from 'react-select';
import moment from 'moment';
import { observer } from 'mobx-react';
import ContentEditable from 'react-contenteditable';

import global from '../Global';
import { randomId, sortFunction } from '../../js/functions';

class Rating extends Component {
    localstate = observable({
        content: this.props.content || [],
        options: [
            { label: 'Name', value: 'name' },
            { label: 'Bewertung', value: 'rating' },
            { label: 'Datum', value: 'date' },
        ],
    });

    addItem() {
        this.localstate.content.items.push({
            id: randomId(5),
            name: '',
            rating: 0,
            comment: '',
            date: moment().format(),
        });

        global.preview.noteModified++;
        this.focusItem(this.localstate.content.items.length - 1);
    }

    removeItem(item) {
        const { items } = this.localstate.content;

        if (items.length <= 1) {
            const element = document.getElementById(item.id);
            const container = element?.parentElement?.parentElement;
            if (container) container.remove();
        } else {
            const index = items.findIndex(x => x.id === item.id);
            if (index >= 0) this.localstate.content.items.splice(index, 1);
        }

        global.preview.noteModified++;
    }

    changeRatingLimit(value = '') {
        const { settings, items } = this.localstate.content;

        if (value.match(/\d+/)) {
            for (const item of items) {
                const percent = (Number(item.rating) * 100) / (Number(settings.ratingLimit) || 1);
                const newRating = (percent * Number(value)) / 100;
                item.rating = Math.round(newRating * 1) / 1;
            }
            global.preview.noteModified++;
        }

        this.localstate.content.settings.ratingLimit = value;
    }

    focusItem(index = -1) {
        const { items } = this.localstate.content;
        const id = items[index]?.id;
        if (!id) return;

        setTimeout(() => {
            const element = document.getElementById(id);
            const text = element?.querySelector('.name');
            text?.focus();
            if (text) text.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 1);
    }

    componentDidMount() {
        const { settings, items } = this.localstate.content;
        sortFunction(items, settings.sorting.prop, settings.sorting.dir);
        if (!this.props.parentstate.starting) this.focusItem(0);
    }

    render() {
        const { selectSmallStyle } = global;
        const { options } = this.localstate;
        const { settings, items } = this.localstate.content;
        const ratings = Array.from({ length: Number(settings.ratingLimit) }, (x, i) => i + 1);

        return (
            <div className="rating" contentEditable={false} data-state={JSON.stringify(this.localstate.content)}>
                <div className="rating-settings">
                    <label>
                        <p>
                            <i className="fal fa-star"></i>
                        </p>
                        <input
                            type="number"
                            min={1}
                            max={10}
                            value={settings.ratingLimit}
                            onChange={e => this.changeRatingLimit(e.target.value)}
                            onBlur={e => {
                                const number = Number(e.target.value);
                                global.preview.noteModified++;
                                this.localstate.content.settings.ratingLimit = !number ? 1 : number;
                            }}
                        />
                    </label>
                    <Select
                        options={options}
                        value={options.find(x => x.value === settings.sorting.prop)}
                        maxMenuHeight="80vh"
                        placeholder="Sortieren"
                        isSearchable={false}
                        styles={selectSmallStyle}
                        data-sortdir={settings.sorting.dir}
                        onChange={select => {
                            this.localstate.content.settings.sorting.prop = select.value;
                            global.preview.noteModified++;
                            sortFunction(items, settings.sorting.prop, settings.sorting.dir);
                        }}
                        components={{
                            DropdownIndicator: () => (
                                <i
                                    className={`fal fa-arrow-${
                                        settings.sorting.dir === 'asc' ? 'down' : 'up'
                                    }-short-wide`}
                                    style={{ margin: '0em 0.5em', cursor: 'pointer' }}
                                    onPointerUp={() => {
                                        this.localstate.content.settings.sorting.dir =
                                            settings.sorting.dir === 'asc' ? 'desc' : 'asc';
                                        global.preview.noteModified++;
                                        sortFunction(items, settings.sorting.prop, settings.sorting.dir);
                                    }}
                                ></i>
                            ),
                        }}
                    />
                </div>
                {items.map((item, i) => (
                    <div key={i} id={item.id} className="rating-item">
                        <ContentEditable
                            className="name"
                            onChange={e => (item.name = e.target.value || '')}
                            html={item.name || ''}
                        />
                        <ContentEditable
                            className="comment"
                            onChange={e => (item.comment = e.target.value || '')}
                            html={item.comment || ''}
                        />
                        <div className="box-rating">
                            <div className="rating-score">
                                <h1>{item.rating}</h1>
                                <span>/</span>
                                <h2>{settings.ratingLimit}</h2>
                            </div>
                            <div className="flex-row">
                                {ratings.map((x, i) => (
                                    <button
                                        key={i}
                                        className={`icon${x <= item.rating ? ' selected' : ''}`}
                                        tabIndex={-1}
                                        onClick={() => {
                                            item.rating = x;
                                            global.preview.noteModified++;
                                        }}
                                    >
                                        <i className="fal fa-star"></i>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <button
                            className="icon small btn-delete"
                            tabIndex={-1}
                            title="Entfernen"
                            onClick={() => this.removeItem(item)}
                        >
                            <i className="fal fa-xmark"></i>
                        </button>
                    </div>
                ))}
                <button className="rect" tabIndex={-1} onClick={() => this.addItem()}>
                    <i className="fal fa-plus-circle"></i>
                </button>
            </div>
        );
    }
}

export default observer(Rating);
