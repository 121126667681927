import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider, redirect} from "react-router-dom";

import App from "./components/App";
import OutletPage from "./components/OutletPage";

import "./fontawesome/pro6/css/all.min.css";
import "./style.css";

import global from "./components/Global";
import {getDir, localLogin, updateSharedFolder, selection} from "./js/helper";
import {decrypt} from "./js/functions";

//############################################################################# Render

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
    {
        element: <App/>,
        children: [
            {
                path: "*",
                element: <OutletPage/>,
                loader: async (data) => {
                    if (global.preview.item) {
                        router.navigate(1);
                        if (global.preview.noteModified >= 5) {
                            const confirmation = window.confirm("Deine Änderungen werden eventuell nicht gespeichert.\nHast du bereits gespeichert, kannst du fortfahren.\n\nMöchtest du fortfahren?");
                            if (!confirmation) return null;
                        }
                        global.preview.item = null;
                        global.contextmenu.show = false;
                        selection([], true);
                        return null;
                    }

                    const path = decrypt(data.params["*"]);

                    if (!global.user) await localLogin();

                    const shouldRedirect = await updateSharedFolder(path, true);
                    if (shouldRedirect) return redirect("/");

                    await getDir(path);
                    return null;
                },
            },
        ],
    },
]);

root.render(
    <RouterProvider router={router}/>
);

export default router;