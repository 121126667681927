import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { motion, AnimatePresence } from 'framer-motion';

import global from './Global';

//############################################################################# Animation

const animation = {
    init: {
        opacity: 0,
    },
    in: {
        opacity: 1,
        transition: {
            opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
        },
    },
    out: {
        opacity: 0,
    },
    content: {
        init: {
            y: 30,
            opacity: 0,
        },
        in: {
            y: 0,
            opacity: 1,
            transition: {
                y: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
            },
        },
        out: {
            y: -30,
            opacity: 0,
        },
    },
};

//#############################################################################

class Modal extends Component {
    closeModal() {
        global.modal = null;
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }

    render() {
        const { modal, loading, screen } = global;

        return (
            <AnimatePresence>
                {modal === this.props.show && (
                    <motion.div
                        id="modal"
                        initial={animation.init}
                        animate={animation.in}
                        exit={animation.out}
                        onMouseUp={e => {
                            if (screen.width <= 1024 && e.target?.id === 'modal') global.modal = null;
                        }}
                    >
                        <motion.div
                            id="modal-content"
                            className={this.props.className}
                            initial={animation.content.init}
                            animate={animation.content.in}
                            exit={animation.content.out}
                        >
                            {this.props.children}
                            <button
                                id="btn-modal-close"
                                className="icon"
                                disabled={loading}
                                onClick={() => this.closeModal()}
                            >
                                <i className="fal fa-xmark"></i>
                            </button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }
}

export default observer(Modal);
