import { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import router from '../index';
import global from '../components/Global';
import {
    db,
    msg,
    getPath,
    lastPart,
    sortData,
    noAccess,
    sortFunction,
    findAccess,
    onContextMenu,
    cleanPath,
    cleanSharePath,
    ext,
} from './functions';

//############################################################################# Get Users

async function getUsers(userName) {
    const users = await db('GET', `users`, {}, []);
    global.users = users;

    if (userName) {
        return users.find(x => x.name === userName) || null;
    } else {
        return users;
    }
}

//############################################################################# Get Dir

async function getDir(paramPath = getPath()) {
    try {
        const { user, sharedFolder } = global;
        let path = `${cleanPath(paramPath)}`;

        global.loading = true;
        global.error404 = false;
        global.folders = [];
        global.files = [];
        global.selected.folders = [];
        global.selected.files = [];
        document.title = path ? lastPart(path) : 'My Data Safe';
        onContextMenu(false);

        const urlParams = new URLSearchParams();

        urlParams.append('user', sharedFolder?.owner || user?.name || '');
        urlParams.append('path', cleanSharePath(path));

        const response = await axios.post(`${global.url}/directory.php`, urlParams);

        if (!response?.data?.success) throw response?.data?.error || response?.data;
        if (response?.data?.code !== 200) throw response?.data?.result;

        const { result } = response.data;
        global.folders = result.items.filter(x => x.type === 'folder');
        global.files = result.items.filter(x => x.type !== 'folder');
        global.sharedFolders = result.sharedItems;

        if (sharedFolder) {
            for (const folder of global.folders) {
                folder.theme = global.users.find(x => x.name === sharedFolder.owner)?.theme || null;
            }
        }

        sortData();
        global.loading = false;
    } catch (error) {
        console.error(error);
        global.loading = false;

        if (typeof error === 'string' && error.includes('No such file or directory')) {
            global.error404 = true;
            return;
        }

        msg('Fehler', error, 'fal fa-xmark-circle');
    }
}

//############################################################################# Update Shared Folder

async function updateSharedFolder(path = '', onLoad = false) {
    if (path.startsWith('shared')) {
        const pathList = path.split('/').filter(x => x !== '' && x !== '/');
        const pathOwner = pathList[1];
        const basePath = cleanPath(pathList.filter((x, i) => i >= 2).join('/'));
        const hiddenPath = cleanPath(pathList.filter((x, i) => i <= 1).join('/'));

        const sharedItems = await db('GET', `shared`, { owner: pathOwner }, []);
        sortFunction(sharedItems, 'path', 'asc');
        const sharedFolder = sharedItems.find(x => basePath.startsWith(cleanPath(x.path)));
        const access = findAccess(sharedFolder?.access);

        if (!sharedFolder || !access) {
            msg('Du hast keinen Zugriff auf diesen Ordner', null, 'fal fa-exclamation-circle');
            if (!onLoad) router.navigate('/');
            return true;
        }

        global.sharedFolder = {
            hiddenPath: `${hiddenPath}/${cleanPath(sharedFolder.path.replace(lastPart(sharedFolder.path), ''))}`,
            basePath: sharedFolder.path.replace(`${lastPart(sharedFolder.path)}/`, ''),
            path,
            owner: sharedFolder.owner,
            access,
        };
    } else {
        global.sharedFolder = null;
    }

    return false;
}

//############################################################################# Action

function action(action, data = {}) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!action) reject();
            const { user, sharedFolder } = global;
            const urlParams = new URLSearchParams();

            urlParams.append('action', action);
            urlParams.append('user', `${sharedFolder?.owner || user?.name || ''}/`);
            urlParams.append('path', cleanSharePath(getPath()));
            urlParams.append('data', JSON.stringify(data));

            global.loading = true;
            const response = await axios.post(`${global.url}/action.php`, urlParams);

            if (!response?.data?.success) throw response?.data?.error || response?.data;
            if (response?.data?.code !== 200) throw response?.data?.result;

            global.loading = false;
            resolve(response.data.result);
        } catch (e) {
            console.error(e);
            global.loading = false;

            if (e?.code === 2) {
                msg('Fehlgeschlagen', 'Ausgewählter Inhalt nicht gefunden', 'fal fa-xmark-circle');
                return;
            }

            if (action === 'copymove' && e?.code === 'ERR_BAD_PATH') {
                msg('Fehlgeschlagen', 'Datei oder Ordner existiert bereits', 'fal fa-xmark-circle');
                return;
            }

            msg('Fehler', typeof e === 'string' ? e : JSON.stringify(e), 'fal fa-xmark-circle');
        }
    });
}

//############################################################################# Copy / Move

async function copyMove(
    userDest = global.sharedFolder ? global.sharedFolder.owner : global.user?.name,
    src = global.selected.copymove.src,
    dest = cleanSharePath(getPath()),
    copymoveAction = global.selected.copymove.action,
    folders = global.selected.copymove.folders,
    files = global.selected.copymove.files
) {
    if (noAccess('upload')) return;
    const { copymove } = global.selected;
    const copymoveItems = [...folders, ...files];

    if (dest === undefined || src === undefined || (folders?.length === 0 && files?.length === 0)) return;
    if (copymoveAction === 'move' && src === dest) {
        msg('Elemente können nicht in den gleichen Ordner verschoben werden', null, 'fal fa-exclamation-circle');
        return;
    }

    await action('copymove', {
        userSrc: `${copymove.userSrc || ''}/`,
        userDest: `${userDest || ''}/`,
        src,
        dest,
        action: copymoveAction,
        folders,
        files,
    });

    update('copymove');

    global.selected.copymove.path = '';
    global.selected.copymove.userSrc = '';
    global.selected.copymove.action = 'move';
    global.selected.copymove.folders = [];
    global.selected.copymove.files = [];
    selection([], true);
    msg(
        copymoveAction === 'move' ? 'Verschoben' : 'Kopiert',
        `${copymoveItems.length === 1 ? `„${copymoveItems[0]}“` : `${copymoveItems.length} Elemente`} nach „${
            lastPart(dest) || 'Startseite'
        }“`,
        'fal fa-check'
    );
}

//############################################################################# Local Login / Logout

async function localLogin() {
    const { host } = global;
    const storageId = localStorage.getItem('loginId');

    if (!storageId) {
        await getUsers();
        return;
    }

    const users = await getUsers();
    const foundUser = users.find(x => x.logins.includes(storageId));

    if (foundUser) {
        await db(
            'SET',
            `users`,
            { name: foundUser.name },
            {
                dateLogin: moment().format(),
            }
        );

        global.user = foundUser;
        global.path = `${host}/user/${foundUser.name}/`;
    } else {
        await localLogout();
    }
}

async function localLogout(logoutAction) {
    const { host } = global;

    global.modal = null;
    global.user = null;
    global.path = `${host}/user/`;
    global.loading = false;

    localStorage.removeItem('loginId');

    msg(
        `Du wurdest ${logoutAction === 'all' ? 'auf allen Geräten ' : ''}abgemeldet`,
        null,
        'fal fa-arrow-right-to-bracket'
    );
    router.navigate('/');
}

//############################################################################# Selection

function selection(list = [], overwrite = false, mode = 'auto') {
    const { selected } = global;

    if (overwrite) {
        global.selected.folders = selected.folders.filter(x => list.map(y => y.dataset.name).includes(x));
        global.selected.files = selected.files.filter(x => list.map(y => y.dataset.name).includes(x));
    }

    for (const element of list) {
        if (!element?.dataset?.name || !element?.dataset?.type) continue;

        const itemName = element.dataset.name;
        const itemType = `${element.dataset.type}s`;

        if (mode === 'add') {
            if (!selected[itemType].includes(itemName)) global.selected[itemType].push(itemName);
        }

        if (mode === 'remove') {
            if (selected[itemType].includes(itemName)) {
                const index = global.selected[itemType].indexOf(itemName);
                global.selected[itemType].splice(index, 1);
            }
        }

        if (mode === 'auto') {
            if (selected[itemType].includes(itemName)) {
                const index = global.selected[itemType].indexOf(itemName);
                global.selected[itemType].splice(index, 1);
            } else {
                global.selected[itemType].push(itemName);
            }
        }
    }
}

//############################################################################# Change Preview Item

async function changePreviewItem(item) {
    const { preview, files, fileTypes } = global;

    if (preview.item) {
        const fileExt = ext(preview.item?.name) || '';
        if (preview.noteModified >= 5 && (fileExt === 'txt' || fileTypes.code.includes(fileExt))) {
            const confirmation = window.confirm(
                'Deine Änderungen werden eventuell nicht gespeichert.\nHast du bereits gespeichert, kannst du fortfahren.\n\nMöchtest du fortfahren?'
            );
            if (!confirmation) return;
        }
    }

    global.contextmenu.show = false;
    global.preview.noteModified = 0;

    if (typeof item === 'string') {
        if (!preview.lastIitem) return;

        const add = item === 'same' ? 0 : item === 'prev' ? -1 : 1;
        let index =
            preview.lastIitem.index + add >= files.length
                ? 0
                : preview.lastIitem.index + add < 0
                ? files.length - 1
                : preview.lastIitem.index + add;

        if (item === 'same') {
            const foundIndex = files.findIndex(x => x.name === preview.lastIitem?.name);
            if (foundIndex >= 0) index = foundIndex;
        }

        if (files[index]) {
            if (preview.lastIitem?.name !== files[index].name) global.preview.item = files[index];
        } else {
            global.preview.item = null;
        }
    } else {
        global.preview.item = files.find(x => x.name === item.name) || null;
    }

    if (global.preview.item) {
        const fileExt = ext(global.preview.item?.name) || '';
        const index = files.findIndex(x => x.name === global.preview.item?.name);

        global.preview.lastIitem = { ...global.preview.item, index };
        if (fileExt === 'txt' || fileTypes.code.includes(fileExt)) global.preview.action = 'loadNote';

        setTimeout(() => {
            const element = document.querySelector(`.preview-item[data-name="${global.preview.item.name}"]`);
            if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 1);
    } else {
        global.preview.lastIitem = null;
        selection([], true);
    }
}

//############################################################################# Update

async function update(action = '', data) {
    const { preview } = global;
    const currentPath = cleanSharePath(getPath()) || '/';

    if (action === 'upload') {
        if (currentPath === data?.path) {
            await getDir();
        }
        return;
    }

    await getDir();

    if (action === 'createFolder') {
        if (preview.item && !global.files.includes(preview.item?.name)) {
            changePreviewItem('same');
        }
    }
}

//############################################################################# Load Thumbnail

class LoadThumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnailError: false,
            imageError: false,
        };
    }

    componentDidCatch() {
        this.setState({ thumbnailError: true });
        if (this.thumbnailError) this.setState({ imageError: true });
    }

    render() {
        const { src, fallback, addition, alt } = this.props;
        const { thumbnailError, imageError } = this.state;

        return thumbnailError ? (
            imageError ? (
                fallback
            ) : (
                <img
                    src={src.replace('thumbnail/', '')}
                    alt={alt}
                    onError={() => this.setState({ imageError: true })}
                />
            )
        ) : (
            <>
                <img src={src} alt={alt} onError={() => this.setState({ thumbnailError: true })} />
                {addition && addition}
            </>
        );
    }
}

//############################################################################# Export

export {
    getUsers,
    getDir,
    updateSharedFolder,
    action,
    copyMove,
    selection,
    localLogin,
    localLogout,
    changePreviewItem,
    update,
    LoadThumbnail,
};
