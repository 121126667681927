import React, { Component } from 'react';
import Popup from 'reactjs-popup';

import global from '../Global';

class EditorActions extends Component {
    render() {
        const { parent } = this.props;
        const { loading, screen } = global;

        return (
            <div className="actions-buttons">
                <button
                    className="icon"
                    disabled={loading}
                    title="Rückgängig"
                    onClick={() => parent._editor.current.editorAction('undo')}
                >
                    <i className="fal fa-arrow-rotate-left"></i>
                </button>
                <button
                    className="icon"
                    disabled={loading}
                    title="Wiederholen"
                    onClick={() => parent._editor.current.editorAction('redo')}
                >
                    <i className="fal fa-arrow-rotate-right"></i>
                </button>
                <span></span>
                <Popup
                    trigger={
                        <button className="icon">
                            <i className="fal fa-font"></i>
                        </button>
                    }
                    position="bottom center"
                    on="hover"
                    {...{
                        contentStyle: {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                        },
                    }}
                >
                    <button
                        className="icon"
                        disabled={loading}
                        title="Fett"
                        onClick={() => parent._editor.current.editorAction('bold')}
                    >
                        <i className="fal fa-f"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Kursiv"
                        onClick={() => parent._editor.current.editorAction('italic')}
                    >
                        <i className="fal fa-italic"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Unterstrichen"
                        onClick={() => parent._editor.current.editorAction('underline')}
                    >
                        <i className="fal fa-underline"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Durchgestrichen"
                        onClick={() => parent._editor.current.editorAction('strikethrough')}
                    >
                        <i className="fal fa-strikethrough"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Überschrift 1"
                        onClick={() => parent._editor.current.editorAction('heading1')}
                    >
                        <i className="fal fa-heading"></i>
                        <span>1</span>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Überschrift 2"
                        onClick={() => parent._editor.current.editorAction('heading2')}
                    >
                        <i className="fal fa-heading"></i>
                        <span>2</span>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Überschrift 3"
                        onClick={() => parent._editor.current.editorAction('heading3')}
                    >
                        <i className="fal fa-heading"></i>
                        <span>3</span>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Überschrift 4"
                        onClick={() => parent._editor.current.editorAction('heading4')}
                    >
                        <i className="fal fa-heading"></i>
                        <span>4</span>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Links ausrichten"
                        onClick={() => parent._editor.current.editorAction('align-left')}
                    >
                        <i className="fal fa-align-left"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Zentrieren"
                        onClick={() => parent._editor.current.editorAction('align-center')}
                    >
                        <i className="fal fa-align-center"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Rechts ausrichten"
                        onClick={() => parent._editor.current.editorAction('align-right')}
                    >
                        <i className="fal fa-align-right"></i>
                    </button>
                </Popup>
                <Popup
                    trigger={
                        <button className="icon">
                            <i className="fal fa-plus-circle"></i>
                        </button>
                    }
                    position="bottom center"
                    on="hover"
                    {...{
                        contentStyle: {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                        },
                    }}
                >
                    <button
                        className="icon"
                        disabled={loading}
                        title="Link"
                        onClick={() => parent._editor.current.editorAction('link')}
                    >
                        <i className="fal fa-link"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Code"
                        onClick={() => parent._editor.current.editorAction('code')}
                    >
                        <i className="fal fa-code"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Lerrzeile oben"
                        onClick={() => parent._editor.current.editorAction('emptyBefore')}
                    >
                        <i className="fal fa-arrow-up-to-dotted-line"></i>
                    </button>
                    <button
                        className="icon"
                        disabled={loading}
                        title="Lerrzeile unten"
                        onClick={() => parent._editor.current.editorAction('emptyAfter')}
                    >
                        <i className="fal fa-arrow-down-to-dotted-line"></i>
                    </button>
                </Popup>
                <span></span>
                {screen.width > 780 && (
                    <>
                        <button
                            className="icon"
                            disabled={loading}
                            title="Liste"
                            onClick={() => parent._editor.current.editorAction('insertList')}
                        >
                            <i className="fal fa-list-ul"></i>
                        </button>
                        <button
                            className="icon"
                            disabled={loading}
                            title="Checkliste / ToDo-Liste"
                            onClick={() => parent._editor.current.editorAction('insertChecklist')}
                        >
                            <i className="fal fa-list-check"></i>
                        </button>
                        <button
                            className="icon"
                            disabled={loading}
                            title="Bewertung"
                            onClick={() => parent._editor.current.editorAction('insertRating')}
                        >
                            <i className="fal fa-star"></i>
                        </button>
                        <button
                            className="icon"
                            disabled={loading}
                            title="Passwort"
                            onClick={() => parent._editor.current.editorAction('insertPassword')}
                        >
                            <i className="fal fa-key"></i>
                        </button>
                    </>
                )}
                {screen.width <= 780 && (
                    <Popup
                        trigger={
                            <button className="icon">
                                <i className="fal fa-grid-2-plus"></i>
                            </button>
                        }
                        position="bottom center"
                        on="hover"
                        {...{
                            contentStyle: {
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                            },
                        }}
                    >
                        <button
                            className="icon"
                            disabled={loading}
                            title="Liste"
                            onClick={() => parent._editor.current.editorAction('insertList')}
                        >
                            <i className="fal fa-list-ul"></i>
                        </button>
                        <button
                            className="icon"
                            disabled={loading}
                            title="Checkliste / ToDo-Liste"
                            onClick={() => parent._editor.current.editorAction('insertChecklist')}
                        >
                            <i className="fal fa-list-check"></i>
                        </button>
                        <button
                            className="icon"
                            disabled={loading}
                            title="Bewertung"
                            onClick={() => parent._editor.current.editorAction('insertRating')}
                        >
                            <i className="fal fa-star"></i>
                        </button>
                        <button
                            className="icon"
                            disabled={loading}
                            title="Passwort"
                            onClick={() => parent._editor.current.editorAction('insertPassword')}
                        >
                            <i className="fal fa-key"></i>
                        </button>
                    </Popup>
                )}
                <span></span>
            </div>
        );
    }
}

export default EditorActions;
