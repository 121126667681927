import React, { Component, createRef } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import Select from 'react-select';

import router from '../index';
import Modal from './Modal';
import global from './Global';
import { db, checkHash, getPath, msg, randomId, focus } from '../js/functions';
import { getUsers, action, localLogin, localLogout } from '../js/helper';

//#############################################################################

const themeColors = {
    blue: 'Blau',
    red: 'Rot',
    green: 'Grün',
    orange: 'Orange',
    yellow: 'Gelb',
    turquoise: 'Türkis',
    lime: 'Limettengrün',
    mint: 'Mint',
    purple: 'Violett',
    pink: 'Pink',
    brown: 'Braun',
    gray: 'Grau',
};

//#############################################################################

class User extends Component {
    constructor() {
        super();
        this._loginPassword = createRef();
        this._name = createRef();
        this._password = createRef();
        this._passwordConfirm = createRef();
        this._passwordNew = createRef();
        this._passwordNewConfirm = createRef();
    }

    localstate = observable({
        form: {
            user: null,
            loginPassword: '',
            name: '',
            password: '',
            passwordConfirm: '',
            passwordNew: '',
            passwordNewConfirm: '',
        },
    });

    async checkLoginForm() {
        const { form } = this.localstate;
        focus(this._loginPassword);

        if (!form.user) {
            msg('Wähle einen Nutzer zum Anmelden', null, 'fal fa-exclamation-circle');
            return;
        }
        if (!form.loginPassword) {
            msg('Gib dein Passwort ein', null, 'fal fa-exclamation-circle');
            return;
        }

        const user = await getUsers(form.user.value);
        if (!user) {
            msg('Nutzer konnte nicht gefunden werden', null, 'fal fa-xmark-circle');
            return;
        }

        await this.checkPassword(user, form.loginPassword);
    }

    async checkRegisterForm() {
        const { form } = this.localstate;
        const users = await getUsers();
        global.loading = false;

        if (!form.name) {
            msg('Gib deinen Namen ein', null, 'fal fa-exclamation-circle');
            focus(this._name);
            return;
        }
        if (form.name === 'link') {
            msg('Dieser Name ist leider reserviert', null, 'fal fa-exclamation-circle');
            return;
        }
        if (
            users.map(x => x.name.toLowerCase().replace(/\s/g, '')).includes(form.name.toLowerCase().replace(/\s/g, ''))
        ) {
            msg('Name bereits vergeben', null, 'fal fa-exclamation-circle');
            focus(this._name);
            return;
        }
        if (form.name.match(/[#/\\?<>|*:“%`´]/)) {
            msg('Folgende Sonderzeichen nicht erlaubt', '# / \\ ? < > | * : “ %  ` ´', 'fal fa-exclamation-circle');
            return;
        }
        if (!form.password) {
            msg('Gib ein Passwort ein', null, 'fal fa-exclamation-circle');
            focus(this._password);
            return;
        }
        if (!form.passwordConfirm) {
            msg('Wiederhole dein Passwort', null, 'fal fa-exclamation-circle');
            focus(this._passwordConfirm);
            return;
        }
        if (form.password !== form.passwordConfirm) {
            msg('Passwörter stimmen nicht überein', null, 'fal fa-exclamation-circle');
            focus(this._passwordConfirm);
            return;
        }

        global.loading = true;

        await action('createUser', {
            name: form.name,
        });

        const loginId = randomId();
        await db(
            'ADD',
            `users`,
            {},
            {
                name: form.name,
                password: `HASH|${form.password}`,
                theme: 'blue',
                logins: [loginId],
                dateLogin: moment().format(),
                dateCreated: moment().format(),
            }
        );

        msg(`Hi, ${form.name}!`, 'Du kannst nun loslegen', 'fal fa-hand-wave');
        localStorage.setItem('loginId', loginId);
        await localLogin();
        router.navigate(getPath(true));
        this.resetForm();
    }

    async checkPassword(userToCheck = null, password = '') {
        if (!userToCheck) return;
        const loginId = randomId();

        global.loading = true;
        const isEqual = await checkHash(password, userToCheck.password);

        if (!isEqual) {
            focus(this._loginPassword);
            global.loading = false;
            msg('Falsches Passwort', null, 'fal fa-xmark');
            return;
        }

        userToCheck.logins.push(loginId);
        await db(
            'SET',
            `users`,
            { name: userToCheck.name },
            {
                logins: userToCheck.logins,
            }
        );

        localStorage.setItem('loginId', loginId);

        msg(`Willkommen zurück, ${userToCheck.name}`, null, 'fal fa-hand-wave');
        await localLogin();
        router.navigate(getPath(true));
        this.resetForm();
    }

    async userLogout(logoutAction) {
        const user = await getUsers(global.user?.name);

        if (!user) {
            await localLogout();
            return;
        }

        const storageId = localStorage.getItem('loginId');
        const idIsAvailable = user.logins.includes(storageId);

        if (logoutAction === 'all') {
            user.logins = [];
        } else {
            if (idIsAvailable) user.logins.splice(user.logins.indexOf(storageId), 1);
        }

        if (idIsAvailable) {
            global.loading = true;
            await db(
                'SET',
                `users`,
                { name: user.name },
                {
                    logins: user.logins,
                }
            );
        }

        await localLogout(logoutAction);
        this.props.localstate.userSettings = false;
    }

    async deleteUser() {
        global.loading = true;

        await action('deleteUser');
        await localLogout();

        global.loading = false;
        global.modal = null;
        this.props.localstate.userSettings = false;
    }

    async changeTheme(theme) {
        const { user } = global;

        global.loading = true;
        await db('SET', `users`, { name: user.name }, { theme });
        global.loading = false;

        global.user.theme = theme;
    }

    async changePassword() {
        const { user } = global;
        const { form } = this.localstate;

        if (!form.passwordNew) {
            msg('Gib ein Passwort ein', null, 'fal fa-exclamation-circle');
            focus(this._passwordNew);
            return;
        }
        if (!form.passwordNewConfirm) {
            msg('Wiederhole dein Passwort', null, 'fal fa-exclamation-circle');
            focus(this._passwordNewConfirm);
            return;
        }
        if (form.passwordNew !== form.passwordNewConfirm) {
            msg('Passwörter stimmen nicht überein', null, 'fal fa-exclamation-circle');
            focus(this._passwordNewConfirm);
            return;
        }

        global.loading = true;

        await db(
            'SET',
            `users`,
            { name: user?.name },
            {
                password: form.passwordNew,
            }
        );

        msg('Dein Passwort wurde geändert', null, 'fal fa-check');
        this.resetForm();
        global.loading = false;
    }

    resetForm() {
        this.localstate.form = {
            user: null,
            loginPassword: '',
            name: '',
            password: '',
            passwordConfirm: '',
            passwordNew: '',
            passwordNewConfirm: '',
        };
        global.modal = null;
    }

    render() {
        const { users, user, loading, selectStyle, screen } = global;
        const { form } = this.localstate;

        return (
            <>
                <Modal show="login" onClose={() => this.resetForm()}>
                    <h1>Anmelden</h1>
                    <label>
                        <p>Nutzer wählen</p>
                        <Select
                            options={users.map(x => ({ label: x.name, value: x.name }))}
                            value={form.user}
                            maxMenuHeight={200}
                            placeholder="Nutzer wählen ..."
                            isDisabled={loading}
                            styles={selectStyle}
                            autoFocus={screen.width > 430}
                            noOptionsMessage={x => x && 'Keine Ergebnisse'}
                            onChange={selected => {
                                this.localstate.form.user = selected;
                                focus(this._loginPassword);
                            }}
                        />
                    </label>
                    <label>
                        <p>Passwort eingeben</p>
                        <input
                            ref={this._loginPassword}
                            type="password"
                            maxLength="50"
                            placeholder="Passwort"
                            value={form.loginPassword}
                            disabled={loading}
                            autoFocus={form.user}
                            onChange={e => (this.localstate.form.loginPassword = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.checkLoginForm()}
                        />
                    </label>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.checkLoginForm()}>
                            <i className="fal fa-arrow-right-to-bracket"></i>
                            <span>Anmelden</span>
                        </button>
                        <button className="default" disabled={loading} onClick={() => (global.modal = 'register')}>
                            <i className="fal fa-plus"></i>
                            <span>Neuer Nutzer</span>
                        </button>
                    </div>
                </Modal>

                <Modal show="register" onClose={() => this.resetForm()}>
                    <h1>Neuer Nutzer</h1>
                    <label>
                        <p>Name eingeben</p>
                        <input
                            ref={this._name}
                            type="text"
                            value={form.name}
                            maxLength="30"
                            placeholder="Name"
                            disabled={loading}
                            autoFocus
                            onChange={e => (this.localstate.form.name = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.checkRegisterForm()}
                        />
                    </label>
                    <label>
                        <p>Passwort festlegen</p>
                        <input
                            ref={this._password}
                            id="input-password"
                            type="password"
                            value={form.password}
                            maxLength="50"
                            placeholder="Passwort"
                            disabled={loading}
                            onChange={e => (this.localstate.form.password = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.checkRegisterForm()}
                        />
                    </label>
                    <label>
                        <p>Passwort wiederholen</p>
                        <input
                            ref={this._passwordConfirm}
                            type="password"
                            value={form.passwordConfirm}
                            maxLength="50"
                            placeholder="Passwort wiederholen"
                            disabled={loading}
                            onChange={e => (this.localstate.form.passwordConfirm = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.checkRegisterForm()}
                        />
                    </label>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.checkRegisterForm()}>
                            <i className="fal fa-plus"></i>
                            <span>Nutzer erstellen</span>
                        </button>
                    </div>
                </Modal>

                <Modal show="changePassword" onClose={() => this.resetForm()}>
                    <h1>Passwort ändern</h1>
                    <label>
                        <p>Neues Passwort</p>
                        <input
                            ref={this._passwordNew}
                            id="input-password"
                            type="password"
                            value={form.passwordNew}
                            maxLength="50"
                            placeholder="Passwort"
                            disabled={loading}
                            autoFocus
                            onChange={e => (this.localstate.form.passwordNew = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.changePassword()}
                        />
                    </label>
                    <label>
                        <p>Passwort wiederholen</p>
                        <input
                            ref={this._passwordNewConfirm}
                            type="password"
                            value={form.passwordNewConfirm}
                            maxLength="50"
                            placeholder="Passwort wiederholen"
                            disabled={loading}
                            onChange={e => (this.localstate.form.passwordNewConfirm = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.changePassword()}
                        />
                    </label>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.changePassword()}>
                            <i className="fal fa-check"></i>
                            <span>Passwort ändern</span>
                        </button>
                    </div>
                </Modal>

                <Modal show="logout">
                    <h1>Abmelden</h1>
                    <h2>
                        Du bist auf {user?.logins.length} {user?.logins.length === 1 ? 'Gerät' : 'Geräten'} angemeldet
                    </h2>
                    <div className="buttons col">
                        <button className="primary" disabled={loading} onClick={() => this.userLogout()}>
                            <i className="fal fa-arrow-right-from-bracket"></i>
                            <span>Nur hier abmelden</span>
                        </button>
                        <button className="default small" disabled={loading} onClick={() => this.userLogout('all')}>
                            <i className="fal fa-laptop-mobile"></i>
                            <span>Überall abmelden</span>
                        </button>
                    </div>
                </Modal>

                <Modal className="modal-theme" show="theme">
                    <h1>Designfarbe</h1>
                    <p>{themeColors[user?.theme]}</p>
                    <div>
                        {Object.keys(themeColors).map((color, i) => (
                            <button
                                key={i}
                                className={user?.theme === color ? 'selected' : null}
                                disabled={loading}
                                data-color={color}
                                onClick={() => this.changeTheme(color)}
                            ></button>
                        ))}
                    </div>
                </Modal>

                <Modal show="deleteUser">
                    <h1>Alles löschen</h1>
                    <h2>
                        Wirklich <b>alles</b> von „{user?.name}“ löschen?
                    </h2>
                    <p>
                        Alle Ordner mit allen Dateien & Inhalten werden unwiderruflich gelöscht.
                        <br />
                        Dieser Schritt ist nicht rückgängig zu machen!
                    </p>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.deleteUser()}>
                            <i className="fal fa-trash"></i>
                            <span>Dauerhaft löschen</span>
                        </button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default observer(User);
