import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ContentEditable from 'react-contenteditable';

import global from '../Global';
import { msg, randomId } from '../../js/functions';

class Password extends Component {
    localstate = observable({
        content: this.props.content || [],
        showPassword: false,
    });

    async copyText(text = '') {
        try {
            await window.navigator.clipboard.writeText(text);
            msg('Kopiert', null, 'fal fa-check');
        } catch (err) {
            msg('Text konnte nicht kopiert werden', null, 'fal fa-xmark-circle');
        }
    }

    generatePassword(password) {
        if (password.length > 0) {
            const confirmation = window.confirm('Möchtest du ein neues Passwort generieren?');
            if (!confirmation) return;
        }

        global.preview.noteModified++;
        this.localstate.content.password = randomId(15, true);
    }

    focusItem() {
        const { content } = this.localstate;
        const id = content.id;
        if (!id) return;

        setTimeout(() => {
            const element = document.getElementById(id);
            const text = element?.querySelector('.description');
            text?.focus();
        }, 1);
    }

    removeComponent() {
        const { content } = this.localstate;
        const element = document.getElementById(content.id);

        if (element.parentElement) {
            element.parentElement?.remove();
            global.preview.noteModified++;
        }
    }

    componentDidMount() {
        if (!this.props.parentstate.starting) this.focusItem();
    }

    render() {
        const { content, showPassword } = this.localstate;

        return (
            <div id={content.id} className="password" contentEditable={false} data-state={JSON.stringify(content)}>
                <ContentEditable
                    className="description"
                    onChange={e => (content.description = e.target.value || '')}
                    html={content.description || ''}
                />
                <div className="box-username">
                    <label>
                        <input
                            type="text"
                            value={content.username}
                            placeholder="Nutzername / Email"
                            onChange={e => (content.username = e.target.value)}
                        />
                    </label>
                    <button
                        className="icon"
                        tabIndex={-1}
                        title="Kopieren"
                        onClick={() => this.copyText(content.username)}
                    >
                        <i className="fal fa-copy"></i>
                    </button>
                </div>
                <div className="box-password">
                    <label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={content.password}
                            placeholder="Passwort"
                            onChange={e => (content.password = e.target.value)}
                        />
                        <button
                            className="icon small btn-show-password"
                            tabIndex={-1}
                            onClick={() => (this.localstate.showPassword = !showPassword)}
                        >
                            <i className={showPassword ? 'fal fa-eye-slash' : 'fal fa-eye'}></i>
                        </button>
                    </label>
                    <button
                        className="icon"
                        tabIndex={-1}
                        title="Passwort generieren"
                        onClick={() => this.generatePassword(content.password)}
                    >
                        <i className="fal fa-lock-hashtag"></i>
                    </button>
                    <button
                        className="icon"
                        tabIndex={-1}
                        title="Kopieren"
                        onClick={() => this.copyText(content.password)}
                    >
                        <i className="fal fa-copy"></i>
                    </button>
                </div>
                <button
                    className="icon small btn-delete"
                    tabIndex={-1}
                    title="Entfernen"
                    onClick={() => this.removeComponent()}
                >
                    <i className="fal fa-xmark"></i>
                </button>
            </div>
        );
    }
}

export default observer(Password);
