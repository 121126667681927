import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ContentEditable from 'react-contenteditable';

import global from '../Global';
import { randomId } from '../../js/functions';

class Checklist extends Component {
    localstate = observable({
        content: this.props.content || [],
    });

    addItem(index = this.localstate.content.length) {
        const id = randomId(5);

        this.localstate.content.splice(index, 0, {
            id,
            checked: false,
            text: '',
        });

        global.preview.noteModified++;
        this.focusItem(index);
    }

    removeItem(index = this.localstate.content.length - 1) {
        const { content } = this.localstate;

        if (content.length <= 1) {
            const element = document.getElementById(content[0]?.id);
            const container = element?.parentElement?.parentElement;
            if (container) container.remove();
        } else {
            this.localstate?.content?.splice(index, 1);
            this.focusItem(index - 1);
        }

        global.preview.noteModified++;
    }

    onKeyDown(e, index = 0, item) {
        const text = item?.text?.replace(/<\/?[^>]+(>|$)/g, '');

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (text) {
                this.addItem(index + 1);
            } else {
                this.removeItem(index);
            }
        }

        if (e.key === 'Backspace' || e.key === 'Delete') {
            if (!text) {
                e.preventDefault();
                this.removeItem(index);
            }
        }
    }

    focusItem(index = -1) {
        const { content } = this.localstate;
        const id = content[index]?.id;
        if (!id) return;

        setTimeout(() => {
            const element = document.getElementById(id);
            const child = element?.querySelector('.checklist-item-text');
            const textSelection = window.getSelection();
            if (!textSelection.anchorNode) return;
            const range = document.createRange();

            range.selectNodeContents(child);
            range.collapse(false);
            textSelection.removeAllRanges();
            textSelection.addRange(range);
            child.focus();
        }, 1);
    }

    componentDidMount() {
        if (!this.props.parentstate.starting) this.focusItem(0);
    }

    render() {
        const { content } = this.localstate;

        return (
            <ul className="checklist" contentEditable={false} data-state={JSON.stringify(content)}>
                {content.map((x, i) => (
                    <li key={JSON.stringify(i)} id={x.id} className="checklist-item">
                        <label>
                            <input type="checkbox" checked={x.checked} onChange={e => (x.checked = e.target.checked)} />
                        </label>
                        <ContentEditable
                            className="checklist-item-text"
                            onKeyDown={e => this.onKeyDown(e, i, x)}
                            onChange={e => (x.text = e.target.value || '')}
                            html={x.text || ''}
                        />
                    </li>
                ))}
            </ul>
        );
    }
}

export default observer(Checklist);
