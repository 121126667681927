import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { motion, AnimatePresence } from 'framer-motion';

import router from '../index';
import global from './Global';
import {
    getPath,
    onContextMenu,
    noAccess,
    findAccess,
    name,
    ext,
    lastPart,
    cleanSharePath,
    fileIcon,
} from '../js/functions';
import { copyMove, selection, LoadThumbnail } from '../js/helper';

class Content extends Component {
    localstate = observable({
        dragShareFolder: null,
        touchHold: {
            timeout: null,
            active: false,
        },
        selectbox: {
            active: false,
            prevItems: [],
            start: { x: 0, y: 0 },
            current: { x: 0, y: 0 },
        },
    });

    filterList(item) {
        const { fileTypes, extension } = global;
        const { props } = global.options.filter;

        if (props.length === 0) return true;

        for (const prop of props) {
            for (const curExt of fileTypes[prop]) {
                if (item.name.includes(`.${curExt}`)) return true;
            }
            if (prop === 'other' && !extension.all.includes(ext(item.name))) return true;
        }

        return false;
    }

    //############################################################################# Selection Box

    setSelectionBox(e, type) {
        if (type === 'start') {
            this.localstate.selectbox.start.x = e.pageX;
            this.localstate.selectbox.start.y = e.pageY;
            if (e.ctrlKey) {
                this.localstate.selectbox.prevItems = [];
            } else {
                const selectedItems = [...global.selected.folders, ...global.selected.files];
                this.localstate.selectbox.prevItems = selectedItems.map(x =>
                    document.querySelector(`[data-name="${x}"]`)
                );
            }
        }

        this.localstate.selectbox.current.x = e.pageX;
        this.localstate.selectbox.current.y = e.pageY;

        const { prevItems, start, current } = this.localstate.selectbox;
        const container = document.querySelector('#content');
        const elements = [];

        const selectbox = {
            x: Math.min(start.x, current.x),
            y: Math.min(start.y, current.y),
            width: Math.abs(current.x - start.x),
            height: Math.abs(current.y - start.y),
        };

        container.querySelectorAll('.item').forEach(element => {
            const itemRect = element.getBoundingClientRect();
            if (
                selectbox.x < itemRect.x + itemRect.width &&
                selectbox.x + selectbox.width > itemRect.x &&
                selectbox.y < itemRect.y + itemRect.height &&
                selectbox.y + selectbox.height > itemRect.y
            ) {
                elements.push(element);
            }
        });

        const diff = prevItems
            .filter(x => !elements.map(y => y.dataset.name).includes(x.dataset.name))
            .concat(elements.filter(x => !prevItems.map(y => y.dataset.name).includes(x.dataset.name)));

        if (diff.length === 0) return;
        this.localstate.selectbox.prevItems = elements;

        selection(diff);
    }

    //############################################################################# Drag

    onDrag(e) {
        const { selected, drag } = global;
        if (!drag.init || (!drag.active && e.movementX === 0 && e.movementY === 0)) return;
        const dropinto = e.target?.dataset?.name && e.target.dataset;
        const dropId = e.target?.id || '';
        const isShared = e.target?.className.includes('folder-shared');
        let userDest = null;
        let dest = null;

        global.drag.active = true;
        this.localstate.dragShareFolder = null;

        if (!global.selected[`${drag.start.type}s`].includes(drag.start.name)) {
            if (e.ctrlKey) {
                global.selected[`${drag.start.type}s`].push(drag.start.name);
            } else {
                global.selected[`${drag.start.type}s`] = [drag.start.name];
            }
        }

        global.drag.pos.x = e.pageX;
        global.drag.pos.y = e.pageY;

        if (dropinto && dropinto.type === 'folder' && !selected.folders.includes(dropinto.name)) {
            dest = `${cleanSharePath(getPath())}${dropinto.name}/`;

            if (isShared) {
                const folder = global.sharedFolders.find(x => x.name === dropinto.name);
                if (folder) {
                    userDest = `${folder.shared.owner}/${folder.shared.basePath.replace(/\/+$/, '')}`;
                    this.localstate.dragShareFolder = folder;
                }
            }
        }

        if (e.ctrlKey && (dropId === 'folders' || dropId === 'files' || dropId === 'content'))
            dest = cleanSharePath(getPath());

        global.drag.dest = dest;
        global.drag.userDest = userDest;
    }

    onDragStop() {
        const { user, selected, sharedFolder, drag, keyPressed } = global;
        const { dragShareFolder } = this.localstate;
        let allowCopyMove = true;

        if (global.drag.active && drag.dest !== null) {
            global.selected.copymove.userSrc = sharedFolder ? sharedFolder.owner : user?.name;
            if (dragShareFolder) {
                const access = findAccess(dragShareFolder.shared.access);
                if (noAccess('upload', access)) allowCopyMove = false;
            }
            if (allowCopyMove)
                copyMove(
                    drag.userDest || undefined,
                    cleanSharePath(getPath()),
                    drag.dest,
                    keyPressed.ctrl ? 'copy' : 'move',
                    selected.folders,
                    selected.files
                );
        }

        global.drag.init = false;
        global.drag.active = false;
        global.drag.start = { name: '', type: '' };
        global.drag.userDest = null;
        global.drag.dest = null;
    }

    //############################################################################# Click / Touch

    onClickDown(e) {
        const isTouch = e?.pointerType === 'touch';
        const { drag } = global;
        const isItem = e?.target?.className?.includes('item');
        const isSharedFolder = e?.target?.className?.includes('folder-shared');
        if (isSharedFolder) return;

        if (isTouch && isItem) {
            this.localstate.touchHold.active = false;
            clearTimeout(this.localstate.touchHold.timeout);

            this.localstate.touchHold.timeout = setTimeout(() => {
                this.localstate.touchHold.active = true;
                selection([e.target]);
            }, 400);

            return;
        }

        if (isItem) {
            const item = e.target.dataset;
            clearTimeout(drag.timeout);

            global.drag.timeout = setTimeout(() => {
                global.drag.init = true;
                global.drag.start = { name: item.name, type: item.type };
                onContextMenu(false);
            }, 150);
        } else {
            if (!global.contextmenu.show && !isTouch) {
                this.localstate.selectbox.active = true;
                this.setSelectionBox(e, 'start');
            }
        }
    }

    onClickUp(e) {
        const { drag } = global;
        const isTouch = e?.pointerType === 'touch';
        const selectboxActive = this.localstate.selectbox.active;
        const touchHoldActive = this.localstate.touchHold.active;
        const element = e?.target;
        const isItem = element?.className.includes('item');

        clearTimeout(this.localstate.touchHold.timeout);
        clearTimeout(drag.timeout);
        onContextMenu(false);
        this.localstate.selectbox.active = false;
        this.localstate.touchHold.active = false;

        if (selectboxActive || drag.active || !detectLeftClick(e)) return;

        if (isItem) {
            if (isTouch) {
                const selectedItems = [...global.selected.folders, ...global.selected.files];
                if (touchHoldActive) return;

                if (selectedItems.length === 0) {
                    if (element.dataset.type === 'folder') {
                        const folder = global.folders.find(x => x.name === element.dataset.name);
                        if (!folder) return;
                        const navigatePath = folder.shared
                            ? `shared/${folder.shared.owner}/${folder.shared.path}/`
                            : `${getPath()}${folder.name}/`;
                        this.props.open(navigatePath, 'folder', 2);
                    } else {
                        this.props.open(element.dataset.name, 'file', 2);
                    }
                } else {
                    selection([element]);
                }
            } else {
                selection([element], !e.ctrlKey, !e.ctrlKey ? 'add' : 'auto');
            }
        }
    }

    //############################################################################# Render

    render() {
        const {
            user,
            path,
            folders,
            sharedFolder,
            sharedFolders,
            files,
            selected,
            loading,
            drag,
            contextmenu,
            error404,
            animation,
            keyPressed,
            extension,
        } = global;
        const { action } = this.props;
        const { selectbox, touchHold } = this.localstate;

        const selectedItems = [...selected.folders, ...selected.files];

        if (folders.length + files.length > 0) {
            return (
                <div
                    id="content"
                    onPointerDown={e => this.onClickDown(e)}
                    onMouseMove={e => {
                        if (selectbox.active) this.setSelectionBox(e);
                        if (drag.init) this.onDrag(e);
                    }}
                    onPointerUp={e => {
                        this.onClickUp(e);
                        this.onDragStop(e);
                    }}
                    onTouchEnd={() => {
                        clearTimeout(touchHold.timeout);
                        this.localstate.touchHold.active = false;
                    }}
                    onMouseLeave={() => {
                        this.localstate.selectbox.active = false;
                    }}
                    onScroll={() => clearTimeout(touchHold.timeout)}
                    onTouchMove={() => clearTimeout(touchHold.timeout)}
                >
                    <div id="folders">
                        {folders.map((folder, i) => {
                            const isSelected = selected.folders.includes(folder.name);
                            return (
                                <AnimatePresence key={i}>
                                    <motion.div
                                        initial={animation.fade.init}
                                        animate={animation.fade.in}
                                        exit={animation.fade.out}
                                        className={
                                            'folder item' +
                                            (isSelected ? ' selected' : '') +
                                            (selected.copymove.action === 'move' &&
                                            selected.copymove.folders.includes(folder.name) &&
                                            selected.copymove.src === getPath()
                                                ? ' copymove'
                                                : '') +
                                            (drag.active && isSelected ? ' dragged' : '') +
                                            (drag.dest === folder.name && !isSelected ? ' dropinto' : '')
                                        }
                                        title={
                                            folder.sharedWith.length > 0
                                                ? `${folder.name} - Geteilt mit: ${folder.sharedWith.join(', ')}`
                                                : folder.name
                                        }
                                        data-name={folder.name}
                                        data-type={folder.type}
                                        onClick={e => {
                                            const isTouch = e.nativeEvent?.sourceCapabilities?.firesTouchEvents;
                                            if (!isTouch)
                                                this.props.open(`${getPath()}${folder.name}/`, 'folder', e.detail);
                                        }}
                                        onContextMenu={e => onContextMenu(e)}
                                    >
                                        <div className="image">
                                            <img
                                                src={`/images/folder/${folder?.theme || user?.theme || 'blue'}.png`}
                                                alt=""
                                            />
                                            {folder.sharedWith.length > 0 && <i className="fas fa-user-group"></i>}
                                        </div>
                                        <div className="text">
                                            <p>{folder.name}</p>
                                        </div>
                                    </motion.div>
                                </AnimatePresence>
                            );
                        })}
                    </div>

                    <div id="files">
                        {files
                            .filter(x => this.filterList(x))
                            .map((file, i) => {
                                const isSelected = selected.files.includes(file.name);
                                const fileExt = ext(file.name);
                                let thumbnail = null;

                                if (extension.imageThumb.includes(fileExt)) {
                                    let imageSrc = `${path}${getPath()}thumbnail/${file.name}`;
                                    if (sharedFolder)
                                        imageSrc = user
                                            ? imageSrc.replace(`${user?.name}/shared/`, '')
                                            : imageSrc.replace('shared/', '');
                                    thumbnail = (
                                        <LoadThumbnail
                                            src={imageSrc}
                                            fallback={<i className="fas fa-image"></i>}
                                            alt=""
                                        />
                                    );
                                }

                                if (extension.videoThumb.includes(fileExt)) {
                                    let imageSrc = `${path}${getPath()}thumbnail/${name(file.name)}.jpg`;
                                    if (sharedFolder)
                                        imageSrc = user
                                            ? imageSrc.replace(`${user?.name}/shared/`, '')
                                            : imageSrc.replace('shared/', '');
                                    thumbnail = (
                                        <LoadThumbnail
                                            src={imageSrc}
                                            fallback={<i className="fad fa-circle-play"></i>}
                                            addition={<i className="fas fa-circle-play videoplay"></i>}
                                            alt=""
                                        />
                                    );
                                }

                                return (
                                    <AnimatePresence key={i}>
                                        <motion.div
                                            initial={animation.fade.init}
                                            animate={animation.fade.in}
                                            exit={animation.fade.out}
                                        >
                                            <div
                                                className={
                                                    'file item' +
                                                    (isSelected ? ' selected' : '') +
                                                    (selected.copymove.action === 'move' &&
                                                    selected.copymove.files.includes(file.name) &&
                                                    selected.copymove.src === cleanSharePath(getPath())
                                                        ? ' copymove'
                                                        : '') +
                                                    (drag.active && isSelected ? ' dragged' : '') +
                                                    (drag.dest === file.name && !isSelected ? ' dropinto' : '')
                                                }
                                                title={file.name}
                                                data-name={file.name}
                                                data-type={file.type}
                                                onClick={e => {
                                                    const isTouch = e.nativeEvent?.sourceCapabilities?.firesTouchEvents;
                                                    if (!isTouch) this.props.open(file.name, 'file', e.detail);
                                                }}
                                                onContextMenu={e => onContextMenu(e)}
                                            >
                                                <div className="image">
                                                    {thumbnail ? thumbnail : <i className={fileIcon(file.name)}></i>}
                                                </div>
                                                <div className="text">
                                                    <p>{file.name}</p>
                                                </div>
                                            </div>
                                        </motion.div>
                                    </AnimatePresence>
                                );
                            })}
                    </div>

                    <AnimatePresence>
                        {getPath() === '' && sharedFolders.length > 0 && (
                            <motion.div
                                id="folders-shared"
                                initial={animation.fade.init}
                                animate={animation.fade.in}
                                exit={animation.fade.out}
                            >
                                <h2>
                                    <i className="fal fa-user-group"></i>Geteilte Ordner
                                </h2>
                                {sharedFolders.map((folder, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="folder-shared"
                                            title={`${folder.name} - Geteilt von ${folder.shared.owner}`}
                                            data-name={folder.name}
                                            data-type={folder.type}
                                            onClick={e => {
                                                const isTouch = e.nativeEvent?.sourceCapabilities?.firesTouchEvents;
                                                this.props.open(
                                                    `shared/${folder.shared.owner}/${folder.shared.path}/`,
                                                    'folder',
                                                    isTouch ? 2 : e.detail
                                                );
                                            }}
                                        >
                                            <div className="image">
                                                <img
                                                    src={`/images/folder/${folder?.theme || user?.theme || 'blue'}.png`}
                                                    alt=""
                                                />
                                                <i className="fas fa-user-group"></i>
                                            </div>
                                            <div className="text">
                                                <p>{folder.name}</p>
                                                <small>{folder.shared.owner}</small>
                                            </div>
                                        </div>
                                    );
                                })}
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <AnimatePresence>
                        {contextmenu.show && (
                            <motion.div
                                id="contextmenu"
                                style={{ top: contextmenu.y, left: contextmenu.x }}
                                initial={animation.slide.init}
                                animate={animation.slide.in}
                                exit={animation.slide.out}
                            >
                                <button
                                    className="inmenu small"
                                    disabled={!selectedItems.length}
                                    title="Herunterladen"
                                    onClick={() => action.current.download()}
                                >
                                    <i className="fal fa-arrow-down-to-line"></i>
                                    <span>Herunterladen</span>
                                </button>
                                {!contextmenu.basicOptions && (
                                    <button
                                        className="inmenu small"
                                        disabled={!selectedItems.length}
                                        title="Teilen"
                                        onClick={() => action.current.onShare()}
                                    >
                                        <i className="fal fa-share-alt"></i>
                                        <span>Teilen</span>
                                    </button>
                                )}
                                <button
                                    className="inmenu small"
                                    disabled={selectedItems.length !== 1}
                                    title="Umbenennen"
                                    onClick={() => action.current.onRename()}
                                >
                                    <i className="fal fa-pen"></i>
                                    <span>Umbenennen</span>
                                </button>
                                {!contextmenu.basicOptions && (
                                    <button
                                        className="inmenu small"
                                        disabled={!selectedItems.length}
                                        title="Kopieren"
                                        onClick={() => action.current.onCopy()}
                                    >
                                        <i className="fal fa-copy"></i>
                                        <span>Kopieren</span>
                                    </button>
                                )}
                                {!contextmenu.basicOptions && (
                                    <button
                                        className="inmenu small"
                                        disabled={!selectedItems.length}
                                        title="Verschieben"
                                        onClick={() => action.current.onMove()}
                                    >
                                        <i className="fal fa-file-export"></i>
                                        <span>Verschieben</span>
                                    </button>
                                )}
                                <button
                                    className="inmenu small"
                                    disabled={selectedItems.length !== 1}
                                    title="Details"
                                    onClick={() => action.current.onDetails()}
                                >
                                    <i className="fal fa-info-circle"></i>
                                    <span>Details</span>
                                </button>
                                <button
                                    className="inmenu small"
                                    disabled={!selectedItems.length}
                                    title="Löschen"
                                    onClick={() => {
                                        if (!noAccess('delete')) global.modal = 'delete';
                                    }}
                                >
                                    <i className="fal fa-trash"></i>
                                    <span>Löschen</span>
                                </button>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {drag.active && (
                        <div id="draggable-preview" style={{ top: drag.pos.y, left: drag.pos.x }}>
                            <i className={selected.folders.length > 0 ? 'fal fa-folders' : 'fal fa-files'}></i>
                            <p>{selectedItems.length}</p>
                            <div id="draggable-info">
                                {drag.dest !== null && (
                                    <>
                                        <i className={keyPressed.ctrl ? 'fal fa-copy' : 'fal fa-file-export'}></i>
                                        <div>
                                            {selectedItems.length === 1 ? selectedItems[0] : selectedItems.length}
                                        </div>
                                        <p>nach</p>
                                        <div>{lastPart(drag.dest) || 'Startseite'}</div>
                                        <p>{keyPressed.ctrl ? 'kopieren' : 'verschieben'}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {selectbox.active && (
                        <div
                            id="selection-box"
                            style={{
                                left: selectbox.start.x < selectbox.current.x ? selectbox.start.x : selectbox.current.x,
                                top: selectbox.start.y < selectbox.current.y ? selectbox.start.y : selectbox.current.y,
                                width: Math.abs(selectbox.current.x - selectbox.start.x),
                                height: Math.abs(selectbox.current.y - selectbox.start.y),
                            }}
                        ></div>
                    )}
                </div>
            );
        } else {
            return (
                <div id="content">
                    {error404 ? (
                        <div id="error404">
                            <h1>404</h1>
                            <h2>Der Ordner oder die Datei konnte nicht gefunden werden</h2>
                            <div className="buttons">
                                <button className="default" onClick={() => router.navigate(-1)}>
                                    <i className="fal fa-arrow-left"></i>
                                    <span>Zurück</span>
                                </button>
                                <button className="primary" onClick={() => router.navigate('/')}>
                                    <i className="fal fa-home"></i>
                                    <span>Startseite</span>
                                </button>
                            </div>
                        </div>
                    ) : !user ? (
                        <div id="no-user">
                            <h1>Du bist abgemeldet</h1>
                            <div className="buttons">
                                <button className="primary" onClick={() => (global.modal = 'login')}>
                                    <i className="fal fa-arrow-right-to-bracket"></i>
                                    <span>Anmelden</span>
                                </button>
                                <button className="default" onClick={() => (global.modal = 'register')}>
                                    <i className="fal fa-user-plus"></i>
                                    <span>Neuer Nutzer</span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        folders.length === 0 &&
                        files.length === 0 &&
                        loading === false && (
                            <div id="no-content">
                                <p>Noch keine Inhalte</p>
                                <button
                                    className="primary"
                                    onClick={() => {
                                        if (!noAccess('upload')) document.getElementById('input-upload').click();
                                    }}
                                >
                                    <i className="fal fa-cloud-upload"></i>
                                    <span>Hochladen</span>
                                </button>
                            </div>
                        )
                    )}
                </div>
            );
        }
    }
}

//############################################################################# Helper

function detectLeftClick(e) {
    if ('buttons' in e) {
        return e.button === 0;
    } else if ('which' in e) {
        return e.which === 1;
    } else {
        return e.button === 0 || e.type === 'click';
    }
}

export default observer(Content);
