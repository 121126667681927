import axios from 'axios';
import global from '../components/Global';

//############################################################################# Get

// SELECT * FROM `account` WHERE `name`='Admin';
// db("GET", "tablename", {id: 1004}, ["col1", "col2"]);

//############################################################################# Set

// UPDATE `config` SET `status`='active' WHERE `name`='starter';
// db("SET", "tablename", {id: 1004}, {col1: "value1", col2: "value2"});

//############################################################################# Add

// INSERT INTO `account`(`nachtmode`,`folder`) VALUES ('true','fG7ZB43S');
// db("ADD", "tablename", {}, {col1: "value1", col2: "value2"});

//############################################################################# Delete

// DELETE FROM `stock` WHERE `sku`='01050403'
// db("DELETE", "tablename", {id: 1004}, {});

//############################################################################# Database

function db(action, tableName, condition = {}, options = {}, async = true) {
    return new Promise(async (resolve, reject) => {
        global.loading = true;
        try {
            //------------------------------------------------------ Check Parameters
            if (!action) {
                console.error(`Undefined "action" in function "db()". Expected: "SET | ADD | GET | DELETE"`);
                return;
            }
            if (!tableName) {
                console.error(`Undefined "tableName" in function "db()". Expected: Any String`);
                return;
            }
            if (!action.match(/(SET|ADD|GET|DELETE)$/)) {
                console.error(`Invalid "action" in function "db()". Expected: "SET | ADD | GET | DELETE"`);
                return;
            }
            // ------------------------------------------------------ Check
            let command = '';
            //------------------------------------------------------ ADD
            if (action === 'ADD') {
                if (Object.keys(options).length === 0) {
                    console.error(`Paramater 3 too short in function "db()". Expected: "{key: value}"`);
                    return;
                }
                const keyString = Object.keys(options)
                    .map(x => `\`${x}\``)
                    .join(',');
                const valueString = Object.values(options)
                    .map(x => {
                        if (x === undefined || x === null) {
                            return 'NULL';
                        } else {
                            if (Array.isArray(x) || typeof x === 'object') x = arr2str(x);
                            return `'${dbValueConvert(x)}'`;
                        }
                    })
                    .join(',');
                command = `INSERT INTO \`${tableName}\` (${keyString}) VALUES (${valueString})`;
            }
            //------------------------------------------------------ SET
            if (action === 'SET') {
                const conditionList = [];
                for (let key in condition) conditionList.push(`\`${key}\`='${dbValueConvert(condition[key])}'`);
                if (conditionList.length === 0) {
                    console.error(`No "condition" defined in function "db()". Expected: "{key: value}"`);
                    return;
                }
                const valueList = [];
                for (let key in options) {
                    let value = options[key];
                    if (value === undefined || value === null) {
                        valueList.push(`\`${key}\`=NULL`);
                    } else {
                        if (Array.isArray(value) || typeof value === 'object') value = arr2str(value);
                        valueList.push(`\`${key}\`='${dbValueConvert(value)}'`);
                    }
                }
                command = `UPDATE \`${tableName}\` SET ${valueList.join(', ')} WHERE ${conditionList.join(' AND ')}`;
            }
            //------------------------------------------------------ GET
            if (action === 'GET') {
                if (!Array.isArray(options)) {
                    console.error(`Invalid "options" in function "db()". Expected: "[ARRAY]"`);
                    return;
                }
                const columnList = options.map(x => `\`${x}\``);
                const conditionList = [];
                for (let key in condition) conditionList.push(`\`${key}\`='${dbValueConvert(condition[key])}'`);
                command = `SELECT ${columnList.length > 0 ? columnList.join(', ') : '*'} FROM \`${tableName}\` ${
                    conditionList.length > 0 ? 'WHERE' : ''
                }${conditionList.join(' AND ')}`;
            }
            //------------------------------------------------------ DELETE
            if (action === 'DELETE') {
                const conditionList = [];
                for (let key in condition) conditionList.push(`\`${key}\`='${dbValueConvert(condition[key])}'`);
                if (conditionList.length === 0) {
                    console.error(`No "condition" defined in function "db()". Expected: "{key: value}"`);
                    return;
                }
                command = `DELETE FROM \`${tableName}\` WHERE ${conditionList.join(' AND ')}`;
            }
            //------------------------------------------------------ Request
            const urlParams = new URLSearchParams();

            urlParams.append('action', action);
            urlParams.append('command', command);

            axios
                .post(`${global.url}/database.php`, urlParams)
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    throw error;
                });
        } catch (error) {
            console.error('Database error:', error);
            global.loading = false;
            reject(error);
        }
    });
}

//############################################################################# Check Hash

function checkHash(str = '', hash = '') {
    return new Promise(async (resolve, reject) => {
        try {
            const urlParams = new URLSearchParams();

            urlParams.append('str', str);
            urlParams.append('hash', hash);

            const response = await axios.post(`${global.url}/checkhash.php`, urlParams);

            if (!response?.data?.success) throw response?.data?.error || response?.data;
            if (response?.data?.code !== 200) throw response?.data?.result;

            resolve(response.data.result);
        } catch (error) {
            reject(error);
        }
    });
}

//############################################################################# Convert

function dbValueConvert(str) {
    return str.toString().replace(/\n/g, '<br>').replace(/'/g, "\\'");
}

function arr2str(array) {
    return JSON.stringify(array);
}

//############################################################################# Export

export { db, checkHash };
