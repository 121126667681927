import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Popup from 'reactjs-popup';

import User from './User';
import global from './Global';
import { Upload } from './Upload';
import { noAccess, installApp } from '../js/functions';

//############################################################################# Effect

const effect = {
    message: {
        init: {
            x: 0,
            y: '100%',
            opacity: 0,
        },
        in: {
            y: 0,
            opacity: 1,
            transition: {
                y: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
            },
        },
        out: {
            x: '100%',
            opacity: 0,
            transition: {
                x: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
            },
        },
    },
};

//#############################################################################

class App extends Component {
    constructor(props) {
        super(props);
        this._upload = React.createRef();
    }

    localstate = observable({
        userSettings: false,
        dropzone: false,
    });

    componentDidMount() {
        global.startup = false;
    }

    render() {
        const { user, msg, loading, animation, screen, startup, installapp } = global;
        const { userSettings, dropzone } = this.localstate;

        return (
            <div
                id="page"
                data-theme={user ? user.theme : 'blue'}
                onDragOver={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.localstate.dropzone = true;
                }}
                onDragLeave={() => {
                    this.localstate.dropzone = false;
                }}
                onDrop={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!noAccess('upload')) this._upload.current.onUpload(e.dataTransfer.files);
                    this.localstate.dropzone = false;
                }}
            >
                <div id="sidebar">
                    <div className="buttons col">
                        {user ? (
                            <button id="btn-user" onClick={() => (this.localstate.userSettings = !userSettings)}>
                                <i className="fad fa-user-circle"></i>
                                <span>{user.name}</span>
                            </button>
                        ) : (
                            <button id="btn-login" className="primary" onClick={() => (global.modal = 'login')}>
                                <i className="fal fa-arrow-right-to-bracket"></i>
                                <span>Anmelden</span>
                            </button>
                        )}
                        <AnimatePresence>
                            {userSettings && (
                                <motion.div
                                    className="buttons col"
                                    initial={animation.slide.init}
                                    animate={animation.slide.in}
                                    exit={animation.slide.out}
                                >
                                    <button disabled={loading} onClick={() => (global.modal = 'logout')}>
                                        <i className="fal fa-arrow-right-from-bracket"></i>
                                        <span>Abmelden</span>
                                    </button>
                                    <button disabled={loading} onClick={() => (global.modal = 'theme')}>
                                        <i className="fal fa-palette"></i>
                                        <span>Designfarbe</span>
                                    </button>
                                    <button disabled={loading} onClick={() => (global.modal = 'changePassword')}>
                                        <i className="fal fa-key"></i>
                                        <span>Passwort ändern</span>
                                    </button>
                                    <button disabled={loading} onClick={() => (global.modal = 'deleteUser')}>
                                        <i className="fal fa-trash"></i>
                                        <span>Alles löschen</span>
                                    </button>
                                    <blockquote></blockquote>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className="buttons col">
                        <button
                            onClick={() => {
                                if (!noAccess('upload')) document.querySelector('#input-upload').click();
                            }}
                        >
                            <i className="fal fa-cloud-upload"></i>
                            <span>Hochladen</span>
                        </button>
                        <Popup
                            trigger={
                                <button>
                                    <i className="fal fa-plus"></i>
                                    <span>Neu erstellen</span>
                                </button>
                            }
                            position="right top"
                            on="hover"
                        >
                            {!user && (
                                <button className="inmenu" onClick={() => (global.modal = 'register')}>
                                    <i className="fal fa-user-plus"></i>
                                    <span>Neuer Nutzer</span>
                                </button>
                            )}
                            <button
                                className="inmenu"
                                disabled={!user}
                                onClick={() => {
                                    if (!noAccess('createFolder')) global.modal = 'createFolder';
                                }}
                            >
                                <i className="fal fa-folder-plus"></i>
                                <span>Neuer Ordner</span>
                            </button>
                            <button
                                className="inmenu"
                                disabled={!user}
                                onClick={() => {
                                    if (!noAccess('createNote')) global.modal = 'createNote';
                                }}
                            >
                                <i className="fal fa-note"></i>
                                <span>Notiz</span>
                            </button>
                        </Popup>
                    </div>

                    <blockquote></blockquote>

                    <Upload ref={this._upload} />
                </div>

                <User localstate={this.localstate} />

                <Outlet />

                <AnimatePresence>
                    {msg.show && (
                        <motion.div
                            id="message"
                            initial={effect.message.init}
                            animate={effect.message.in}
                            exit={effect.message.out}
                        >
                            {msg.icon && <i className={msg.icon}></i>}
                            <div>
                                <h1>{msg.headline}</h1>
                                <p>{msg.text}</p>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {dropzone && (
                        <motion.div
                            id="dropzone"
                            initial={animation.fade.init}
                            animate={animation.fade.in}
                            exit={animation.fade.out}
                        >
                            <h1>Zum Hochladen hier loslassen</h1>
                            <i className="fal fa-arrow-down-right"></i>
                            <i className="fal fa-arrow-down-left"></i>
                            <i className="fal fa-arrow-up-right"></i>
                            <i className="fal fa-arrow-up-left"></i>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {loading && (
                        <motion.div
                            id="loading"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <motion.div
                                id="loading-spinner"
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: { opacity: { ease: [0.3, 0.93, 0.35, 1], delay: 3 } },
                                }}
                            >
                                <span></span>
                                <p>Wird geladen ...</p>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {installapp.show && screen.width <= 820 && (
                        <motion.div
                            id="appinstall"
                            initial={animation.fade.init}
                            animate={animation.fade.in}
                            exit={animation.fade.out}
                        >
                            <h1>My Data Safe als App installieren?</h1>
                            <div className="buttons col">
                                <button className="primary" onClick={() => installApp()}>
                                    <i className="fal fa-download"></i>
                                    <span>Ja, installieren</span>
                                </button>
                                <button
                                    className="default"
                                    onClick={() => {
                                        global.installapp.show = false;
                                        global.installapp.event = null;
                                    }}
                                >
                                    <i className="fal fa-arrow-left"></i>
                                    <span>Nein, im Browser bleiben</span>
                                </button>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {startup && (
                        <motion.div
                            id="appstart"
                            initial={{ opacity: 1 }}
                            animate={animation.fade.in}
                            exit={animation.fade.out}
                        >
                            <i className="fas fa-cloud"></i>
                            <h1>My Data Safe</h1>
                            <div>
                                <span></span>
                                <p>Wird geladen ...</p>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        );
    }
}

export default observer(App);
