import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import NoteActions from './NoteActions';
import { Editor, EditorActions } from './Editor';

import global from '../Global';
import { msg, noAccess, ext } from '../../js/functions';
import { action } from '../../js/helper';

class Note extends Component {
    constructor() {
        super();
        this._editor = React.createRef();
    }

    localstate = observable({
        initialNoteContent: '',
        noteTextMode: 'editor',
        noteContent: '',
        showEditor: false,
    });

    async loadNote() {
        const { preview, fileTypes } = global;
        const fileExt = ext(preview.item?.name) || '';

        this.localstate.initialNoteContent = '';
        this.localstate.noteContent = '';
        this.localstate.showEditor = false;

        const noteContent = await action('loadNote', {
            name: preview.item?.name || '',
        });

        let mode = noteContent.match(/<[^>]*>/g) || !noteContent ? 'editor' : 'text';
        if (fileTypes.code.includes(fileExt)) mode = 'text';
        this.localstate.initialNoteContent = noteContent;
        this.changeNoteTextMode(mode, noteContent);
        this.localstate.noteTextMode = mode;
    }

    async saveNote() {
        const { preview, loading } = global;
        const { noteTextMode } = this.localstate;

        const noteContent =
            noteTextMode === 'editor'
                ? this._editor?.current?._editorContent?.current?.innerHTML || ''
                : this.localstate.noteContent;

        if (loading) {
            msg('Nicht gespeichert', 'Ladevorang läuft noch', 'fal fa-exclamation-circle');
            return;
        }

        await action('saveNote', {
            name: preview.item?.name || '',
            content: noteContent,
        });

        global.preview.noteModified = 0;
        this.localstate.initialNoteContent = this.localstate.noteContent;
        msg('Notiz gespeichert', null, 'fal fa-check');
    }

    changeNoteTextMode(mode, content) {
        const { noteTextMode, noteContent } = this.localstate;
        this.localstate.showEditor = false;

        if (mode) {
            this.localstate.noteTextMode = mode;
        } else {
            this.localstate.noteTextMode = noteTextMode === 'editor' ? 'text' : 'editor';
        }

        if (this.localstate.noteTextMode === 'editor') {
            this.localstate.noteContent = content ? content.replace(/\n/g, '<br>') : noteContent.replace(/\n/g, '<br>');
            this.localstate.showEditor = true;
        } else {
            this.localstate.noteContent = content ? content.replace(/<br>/g, '\n') : noteContent.replace(/<br>/g, '\n');
        }
    }

    componentDidMount() {
        this.loadNote();
    }

    componentDidUpdate() {
        if (global.preview.action === 'loadNote') {
            global.preview.action = null;
            this.loadNote();
        }
    }

    render() {
        const { loading, preview, fileTypes, screen } = global;
        const { noteTextMode, initialNoteContent, noteContent, showEditor } = this.localstate;
        const fileExt = ext(preview.item?.name) || '';
        const isCode = fileTypes.code.includes(fileExt);

        return (
            <div id="note">
                <div id="note-actions">
                    <div>
                        <button
                            className="primary small"
                            disabled={loading || (noteTextMode === 'text' && initialNoteContent === noteContent)}
                            onClick={() => {
                                if (!noAccess('contentEditing')) this.saveNote();
                            }}
                        >
                            <i className="fal fa-save"></i>
                            <span>Speichern</span>
                        </button>
                    </div>
                    {noteTextMode === 'editor' && screen.width > 650 ? (
                        <div>
                            <EditorActions parent={this} />
                            <NoteActions parent={this} />
                        </div>
                    ) : (
                        screen.width > 650 && <NoteActions parent={this} />
                    )}
                    <div>
                        <button
                            className="primary small"
                            disabled={loading || isCode}
                            onClick={() => {
                                const { preview } = global;

                                if (preview.noteModified >= 5) {
                                    const confirmation = window.confirm(
                                        'Deine Änderungen werden eventuell nicht gespeichert.\nHast du bereits gespeichert, kannst du fortfahren.\n\nMöchtest du fortfahren?'
                                    );
                                    if (!confirmation) return;
                                }

                                this.changeNoteTextMode();
                            }}
                        >
                            <i className="fal fa-pen-to-square"></i>
                            <span>{noteTextMode === 'editor' ? 'Nur Text' : 'Editor'}</span>
                        </button>
                    </div>
                </div>
                {screen.width <= 650 && (
                    <div id="note-actions-mobile">
                        <EditorActions parent={this} />
                        <NoteActions parent={this} />
                    </div>
                )}
                <div
                    id="note-content"
                    className={isCode ? 'iscode' : ''}
                    onKeyDown={e => {
                        if (e.ctrlKey && e.key === 's') {
                            e.preventDefault();
                            if (!noAccess('contentEditing')) this.saveNote();
                        }
                    }}
                >
                    {noteTextMode === 'editor' ? (
                        showEditor && <Editor ref={this._editor} parent={this} />
                    ) : (
                        <textarea
                            value={noteContent}
                            onChange={e => {
                                this.localstate.noteContent = e.currentTarget?.value || '';
                                global.preview.noteModified++;
                            }}
                            spellCheck={!isCode}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default observer(Note);
